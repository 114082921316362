export const defaultAssests = {
  Bnb: { debtWeight: "400000000000000000", type: "physical" },
  Crv: { debtWeight: "400000000000000000", type: "physical" },
  Dai: { debtWeight: "400000000000000000", type: "physical" },
  Eqd: { debtWeight: "0", type: "synthetic" },
  Eth: { debtWeight: "400000000000000000", type: "physical" },
  Busd: { debtWeight: "400000000000000000", type: "physical" },
  Gens: { debtWeight: "0", type: "native" },
  Lpt0: { debtWeight: "400000000000000000", type: "lp" },
  Usdc: { debtWeight: "400000000000000000", type: "physical" },
  Usdt: { debtWeight: "400000000000000000", type: "physical" },
  Wbtc: { debtWeight: "400000000000000000", type: "physical" },
};
