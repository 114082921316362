import "../interfaces/augment-api";
import "../interfaces/augment-types";
export default (api) => {
    return {
        assetInfo: api.query.eqAssets.assets,
        aggregates: api.query.eqAggregates.totalUserGroups,
        balances: api.query.eqBalances.account,
        rates: api.query.oracle?.pricePoints,
        // Group
        hasGroup: api.query.eqAggregates.accountUserGroups,
        aggregatesByGroup: api.query.eqAggregates.totalUserGroups,
        // Balance
        getBalance: api.query.eqBalances.account,
        // Bridge
        getAssetByResourceId: api.query.eqBridge?.resources,
        // Claim
        // @ts-ignore
        getClaim: api.query.claims?.claims,
        // @ts-ignore
        getClaimSigning: api.query.claims?.signing,
        // @ts-ignore
        getClaimVesting: api.query.claims?.vesting,
        // @ts-ignore
        getTotalClaim: api.query.claims?.total,
        // Curve
        getPool: api.query.curveAmm?.pools,
        // Lockdrop
        getLocks: api.query.eqLockdrop?.locks,
        getLockTime: api.query.eqLockdrop?.lockStart,
        // SubAccounts
        getAddress: api.query.subaccounts.subaccount,
        getOwner: api.query.subaccounts.ownerAccount,
        // Vesting
        getVested: api.query.vesting?.vested,
        getVested2: api.query.vesting2?.vested,
        getVesting: api.query.vesting?.vesting,
        getVesting2: api.query.vesting2?.vesting,
        // Volatility
        getMetrics: api.query.financial.metrics,
        // getPrices: api.query.volatility.prices,
        // getVolatility: api.query.volatility.volatility,
        // Oracle
        getRate: api.query.oracle?.pricePoints,
        // margin call
        maintenanceSince: api.query.eqMarginCall.maintenanceTimers,
        getMmPools: api.query.mmPool.pools,
        getMmManagers: api.query.mmPool.managers,
        getMmMarketMakers: api.query.mmPool.marketMakers,
        // DEX
        dexActualChunksByAsset: api.query.eqDex?.actualChunksByAsset,
        dexOrdersByAssetAndChunkKey: api.query.eqDex?.ordersByAssetAndChunkKey,
        dexBestPriceByAsset: api.query.eqDex?.bestPriceByAsset,
    };
};
