import styled from "styled-components";
import { Box } from "@equilab/ui/lib/components/grid";

export const Wrapper = styled.div`
  width: 366px;
  border: 1px solid #dabaec;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #ffffff;
  padding: 20px 47px;
  margin-right: 108px;

  .header {
    display: flex;
  }

  .header__address > span {
    display: none;
  }

  .token {
    flex-wrap: nowrap;

    height: 41px;
    width: 100%;

    padding: 0 7px;

    cursor: pointer;
    position: relative;
    transition: 0.2s;

    &.disabled {
      cursor: default;
    }

    svg {
      margin-right: 12px;
      min-width: 24px;
      width: 24px;
      height: 24px;

      background: linear-gradient(
        270.18deg,
        #ffddee -11.96%,
        #e4dcff 26.28%,
        #dae8fc 80.25%
      );
      border-radius: 50%;

      circle {
        fill: unset;
      }
    }

    ::after,
    ::before {
      content: "";
      position: absolute;
      background: ${(props) => props.theme.colors.border};
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      transition: 0.2s;
    }

    ::after {
      bottom: 0;
    }

    ::before {
      top: -1px;
    }

    .name {
      margin-right: 12px;
      min-width: 35px;
      text-transform: uppercase;
    }

    .amount {
      margin-left: 16px;
      margin-right: 0;
    }

    .value {
      color: ${(props) => props.theme.colors.gray};
      width: 80px;
      text-align: right;
    }

    :hover {
      background: ${(props) => props.theme.colors?.secondary};
      box-shadow: ${(props) => props.theme.sidebar?.hoverBoxShadow};

      ::after,
      ::before {
        left: 0;
        right: 0;
        width: 100%;
      }

      .value {
        color: ${(props) => props.theme.colors.text};
      }
    }
  }

  .transfer-label {
    display: none;
  }

  .wallet-balance {
    padding-left: 0;
    padding-right: 0;
  }

  .header {
    margin-bottom: 16px;

    .settings {
      cursor: pointer;

      path {
        stroke: ${(props) => props.theme.colors.pale};
      }
    }
  }

  .balances::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.background};
  }

  .row.btns {
    background: ${(props) => props.theme.colors.background};
    margin: 0 -47px 0 -47px;

    padding-left: 54px;
    padding-right: 54px;

    width: unset;

    button {
      background: transparent;
      border: none;
      color: ${(props) => props.theme.colors.text};
      font-size: 9px;
      max-height: 24px;
    }

    button:first-of-type {
      justify-content: flex-start;
      padding-left: 0;
    }
    button:last-of-type {
      justify-content: flex-end;
      padding-right: 0;
    }
  }

  .input-token-row {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    .ui-input__wrapper {
      padding: 0;
    }

    .ui-select__wrapper {
      padding: 20px 0px 0 0;
    }

    .ui-input__content,
    .ui-select__content {
      height: 48px;
    }

    .ui-input__subscript {
      bottom: -12px;
    }

    .ui-input--disabled .ui-input__content,
    .ui-select--disabled .ui-select__content {
      background: ${(props) => props.theme.colors.pale};
    }

    .ui-input--disabled .ui-input__input:disabled {
      color: ${(props) => props.theme.colors.text};
    }
  }

  .eth-connect > span {
    font-size: 16px;
  }

  .deposit-step {
    padding-left: 0;
  }

  .label {
    background: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    padding: 12px 16px;
  }

  .eth-faucet-info {
    position: inherit !important;
    margin-left: 0;
    margin-right: 0;

    & > a > span {
      font-size: 10px !important;
    }
  }

  .addresses {
    margin-top: 16px;
  }

  .wallet {
    border-bottom: 1px ${(props) => props.theme.colors.border} solid;
    height: 43px;
    width: 100%;
    margin: 0;
    padding: 5px 0;

    flex-wrap: nowrap;
    align-items: center;

    &__balance {
      border: 1px solid ${(props) => props.theme.colors.border};
      border-radius: 4px;

      min-height: 41px;

      padding: 0 16px;
      margin: 0 16px;

      display: flex;
      align-items: center;

      .name {
        margin-right: 12px;
        min-width: 35px;
        text-transform: uppercase;
      }

      .amount {
        margin-left: auto;
        margin-right: 8px;
      }

      .value {
        color: ${(props) => props.theme.colors.gray};
        width: 80px;
        text-align: right;
      }
    }

    .ui-radio-button {
      width: 100%;
      justify-content: space-between;
      padding-left: 7px;
      flex-direction: row-reverse;

      .ui-radio-button__input {
        margin-right: 0;
        margin-left: 14px;
        border: 1px solid ${(props) => props.theme.colors.gray};
      }

      .ui-radio-button__input__checked {
        background: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .transaction-hash {
    height: 40px;
    background: ${(props) => props.theme.colors.pale};
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 12px;
  }

  .transaction-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 3px 0;
    min-height: 26px;
  }

  .transfer-destination,
  .withdraw-destination {
    margin-top: 32px;
  }

  .transfer-submit,
  .withdraw-submit {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .deposit-success__header {
    padding-left: 0;
    padding-right: 0;
  }

  .deposit-success__content {
    margin-left: 0;
    margin-right: 0;
  }

  .acc-item-wrapper {
    border-top: 1px solid ${(props) => props.theme.colors.border};
  }

  .ui-action-wrapper {
    overflow-x: hidden;
  }

  .ui-action-wrapper::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.background};
  }

  .ui-select__selected > div,
  .ui-select__modal > div {
    color: ${(props) => props.theme.colors.text} !important;
    font-family: ${(props) => props.theme.fonts.text};
  }
`;

export const CCBox = styled(Box)`
  .ui-select__wrapper {
    margin-bottom: 18px;
  }
`;
