import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useObsState } from "@equilab/ui/lib/hooks/rxjs";
import BigNumber from "bignumber.js";
import { useCallback } from "react";
import { usePrices } from "../observables/prices";

const BIG_ZERO = new BigNumber(0);

const Context = createSimpleContext(
  "convert-feature",
  () => {
    throw new Error("not implemented");
  },
  () => {
    const observable = usePrices();
    const prices = useObsState(observable);

    return useCallback(
      (
        amount: string | number | bigint | BigNumber | undefined,
        from: string,
        to: string,
      ) => {
        let value: BigNumber;

        switch (typeof amount) {
          case "string":
          case "number":
            value = new BigNumber(amount, 10);
            break;
          case "bigint":
            value = new BigNumber(amount.toString(), 10);
            break;
          default:
            value = amount ?? new BigNumber(0, 10);
        }

        if (from === to) {
          return value;
        }

        if (to !== "Eqd" && to !== "Usd" && from !== "Eqd" && from !== "Usd") {
          console.warn(
            `convert is possible only to USD, received: ${JSON.stringify({
              from,
              to,
            })}`,
          );

          return BIG_ZERO;
        }

        let token;
        let exp;

        if (from === "Eqd" || from === "Usd") {
          token = to;
          exp = -1;
        } else {
          token = from;
          exp = 1;
        }

        return prices?.[token]?.pow(exp)?.times(value) ?? value;
      },
      [prices],
    );
  },
);

/** @deprecated do not usually need it; we use prices in USD */
export const useConvert = Context.useContext;
/** @deprecated */
export const ConvertProvider = Context.Provider;
