import "@equilab/api/equilibrium/interfaces/types-lookup";
import { memo } from "@polkadot/api-derive/util";
import BN from "bn.js";
import { map, mergeMap } from "rxjs/operators";
import { u64FromCurrency } from "../../util";
const bnToStringWithBase = (num, dec = 9) => {
    const TEN = new BN(10);
    const pow = new BN(dec);
    const div = TEN.pow(pow);
    const int = num.div(div);
    const fra = num.mod(div);
    const res = `${int.toString(10)}.${fra.toString(10).padStart(dec, "0")}`;
    return res;
};
export var Sides;
(function (Sides) {
    Sides["Buy"] = "buy";
    Sides["Sell"] = "sell";
})(Sides || (Sides = {}));
export const orders = (instanceId, api) => memo(instanceId, (token) => {
    const asset = u64FromCurrency(token);
    return api.query.eqDex.actualChunksByAsset(asset).pipe(mergeMap((chunks) => api.query.eqDex.ordersByAssetAndChunkKey.multi(chunks.map((chunk) => [asset, chunk]))), map((bundles) => bundles.reduce((prev, bundle) => {
        return [
            ...prev,
            ...bundle.map((order) => ({
                id: order.orderId.toNumber(),
                account: order.accountId.toString(),
                side: order.side.isBuy ? Sides.Buy : Sides.Sell,
                price: bnToStringWithBase(order.price),
                amount: bnToStringWithBase(order.amount),
                createdAt: order.createdAt.toString(10),
                expirationTime: order.expirationTime.toString(10),
            })),
        ];
    }, [])));
});
