const testPrefix = "Pay TEST EQ to the TEST account:";
const prefix = "Pay EQ to the account:";


export const STATEMENT = Buffer.from(
  "492068657265627920616772656520746f20746865207465726d73206f66207468652073746174656d656e742077686f7365205348412d323536206861736820697320633039613937616335393637653135393731396533373938633164373765343031623631633539653237333538393763636665346439663666396334613862322e202854686973206d617920626520666f756e64206174207468652055524c3a2068747470733a2f2f657175696c69627269756d2e696f2f746f6b656e737761702f646f63732f746f6b656e5f737761705f742663732e70646629",
  "hex",
);

export const PREFIX = prefix;
export const TEST_PREFIX = testPrefix;
