import type { Api, ChainApiTypes } from "@equilab/api";
import type { SubmittableExtrinsic } from "@polkadot/api/types";
import type { ISubmittableResult } from "@polkadot/types/types";
import { LPT } from "@equilab/ui/lib/components/icons/assets";
import theme from "@equilab/ui/lib/theme/curve";
import assert from "assert";
import React from "react";

export type GetProps<T> = T extends React.FC<infer Props> ? Props : T;

const isNetType = (
  rawNetType: string,
): rawNetType is "Eq" | "EqNext" | "Gens" =>
  ["Eq", "EqNext", "Gens"].includes(rawNetType);

export const getConfig = () => {
  const nodes = process.env.REACT_APP_NETWORK_NODES?.split(";") ?? [
    "wss://node.genshiro.io",
  ];

  const names = process.env.REACT_APP_NETWORK_NAMES?.split(";") ?? ["Genshiro"];
  const types = process.env.REACT_APP_NETWORK_TYPES?.split(";") ?? ["Gens"];
  const ss58 = process.env.REACT_APP_NETWORK_SS58?.split(";") ?? ["67"];
  assert(nodes.length === names.length, "Incorrect network group");
  assert(names.length === types.length, "Incorrect network group");
  assert(names.length === ss58.length, "Incorrect network group");

  return Object.freeze({
    apiBaseUrl: "https://apiv3.equilibrium.io/api",
    networks: nodes.map((node, i) => {
      const netType = types[i];

      if (isNetType(netType)) {
        return {
          node,
          type: netType,
          name: names[i],
          ss58: Number(ss58[i]),
        };
      } else {
        throw new Error("Incorrect network type");
      }
    }),
    theme,
    lsKey: "curve-networks",
    notificationTheme: theme.notification!,
    poolInfo: {
      0: {
        icon: LPT,
        ticker: "USD",
        title: "Stable",
      },
      1: {
        icon: LPT.bind(null, { num: 1 }),
        ticker: "USD",
        title: "Stable",
      },
      _default: {
        icon: LPT.bind(null, { num: 2 }),
        ticker: "USD",
        title: "Stable",
      },
    },
  });
};

export const wrapExtrinsic = (
  api: Api<ChainApiTypes>,
  ex: SubmittableExtrinsic<"promise", ISubmittableResult>,
  opts?: { debugVal?: string },
) => ({
  paymentInfo: ex.paymentInfo.bind(ex),

  send: (address: string) =>
    new Promise<void>(async (resolve, reject) => {
      let resolved = false;

      try {
        const unsub = await ex.signAndSend(address, { nonce: -1 }, (res) => {
          if (resolved) {
            return;
          }

          try {
            console.log(res?.events, opts?.debugVal);

            if (res.status.isInBlock || res.status.isFinalized) {
              for (const {
                event: { data, section, method },
              } of res.events) {
                if (section === "system" && method === "ExtrinsicSuccess") {
                  resolve();
                  resolved = true;
                  unsub();
                  return;
                }

                if (
                  (section === "system" && method === "ExtrinsicFailed") ||
                  (section === "utility" && method === "BatchInterrupted")
                ) {
                  const [error, misc] = data;

                  if (error.toJSON() === 1 && misc) {
                    // TODO
                    continue;
                  }

                  let message: string | undefined;

                  if ((error as any).isModule) {
                    const err = api._api.registry.findMetaError(
                      (error as any).asModule,
                    );
                    message = err?.docs.join("\n");
                  } else {
                    message = error?.toString();
                  }

                  throw new Error(message ?? "Unknown error");
                }
              }
            }
          } catch (e) {
            reject(e);
            unsub();
            resolved = true;
            return;
          }
        });

        if (!unsub) {
          throw new Error("No unsub function");
        }
      } catch (e) {
        reject(e);
      }
    }),
});

const ASSETS = ["Eqd", "Busd", "Gens", "Usdt", "Usdc", "Dai"];

export const assetsFilter = (asset?: string): boolean => {
  if (!asset) return false;
  if (asset.includes("Lpt")) return true;
  if (ASSETS.includes(asset)) return true;
  return false;
};
