import styled from "styled-components";
import Text from "@equilab/ui/lib/components/text";
import { t } from "ttag";

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
`;

const Loading = () => (
  <LoadingWrapper>
    <Text.H2 fontSize={32} fontFamily="SpartanExtraBold">
      {t`Loading`}...
    </Text.H2>
  </LoadingWrapper>
);

export default Loading;
