import { names } from "./assets/names";
import { bestPrice } from "./dex/best-price";
import { orders } from "./dex/orders";
const derives = {
    // @ts-ignore
    assets: { names },
    // @ts-ignore
    dex: { bestPrice, orders },
};
export default derives;
