import LocaleRouter from "@equilab/ui/lib/components/locale-router";
import { DeviceProvider } from "@equilab/ui/lib/contexts/device";
import { NotificationsProvider } from "@equilab/ui/lib/contexts/notifications";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { EthereumProvider } from "@equilab/ui/lib/contexts/ethereum";
import App from "./components/app";
import Compat from "./components/compat";
import { Provider } from "./contexts";
import { locales } from "./locales";
import reportWebVitals from "./reportWebVitals";
import { getConfig } from "./util";

const { notificationTheme, theme, ...config } = getConfig();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DeviceProvider>
        <NotificationsProvider theme={notificationTheme}>
          <ThemeProvider theme={theme}>
            <EthereumProvider>
              <Provider name="curve-eq" {...config}>
                <LocaleRouter langs={locales}>
                  <Compat />
                  <App />
                </LocaleRouter>
              </Provider>
            </EthereumProvider>
          </ThemeProvider>
        </NotificationsProvider>
      </DeviceProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
