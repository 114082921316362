export default (api) => {
    const getDy = 
    // @ts-ignore
    api.rpc.equilibriumCurveAmm?.getDy;
    const getWithdrawOneCoin = api.rpc.equilibriumCurveAmm?.getWithdrawOneCoin;
    return {
        getDy,
        getWithdrawOneCoin,
    };
};
