import "../interfaces/augment-api";
import "../interfaces/augment-types";
import { u64FromCurrency } from "../util";
export default (api) => ({
    batch: api.tx.utility?.batch,
    sudo: api.tx.sudo?.sudo,
    fromSubaccount: api.tx.subaccounts.transferFromSubaccount,
    toSubaccount: api.tx.subaccounts.transferToSubaccount,
    dexCreateOrder: api.tx.eqDex?.createOrder,
    dexDeleteOrder: api.tx.eqDex?.deleteOrderExternal,
    // Balance
    transfer: (asset, to, value) => api.tx.eqBalances.transfer({ 0: u64FromCurrency(asset) }, to, value),
    // Balance Sudo
    deposit: (...args) => api.tx.sudo.sudo(api.tx.eqBalances.deposit(...args)),
    withdraw: (...args) => api.tx.sudo.sudo(api.tx.eqBalances.burn(...args)),
    // Bridge
    withdrawExternal: api.tx.eqBridge.transferNative,
    // Claim
    // @ts-ignore
    claim: api.tx.claims?.claim,
    // @ts-ignore
    claimAttest: api.tx.claims?.claimAttest,
    // Claim Sudo
    // @ts-ignore
    mintClaim: (...args) => 
    // @ts-ignore
    api.tx.sudo.sudo(api.tx.claims?.mintClaim(...args)),
    // Curve
    curveExchange: api.tx.curveAmm?.exchange,
    curveAdd: api.tx.curveAmm?.addLiquidity,
    curveRemove: api.tx.curveAmm?.removeLiquidity,
    curveRemoveImbalance: api.tx.curveAmm?.removeLiquidityImbalance,
    curveRemoveOne: api.tx.curveAmm?.removeLiquidityOneCoin,
    // Lockgrop
    // @ts-ignore
    lockdrop: api.tx.eqLockdrop?.lock,
    // Subaccounts
    transferFrom: (subaccType, asset, amount) => api.tx.subaccounts.transferFromSubaccount(subaccType, { 0: u64FromCurrency(asset) }, amount),
    transferTo: (subaccType, asset, value) => api.tx.subaccounts.transferToSubaccount(subaccType, { 0: u64FromCurrency(asset) }, value),
    // Vesting
    vest: api.tx.vesting.vest,
    vestTo: api.tx.vesting.vestOther,
    // Whitelist Sudo
    whitelist: (...args) => api.tx.sudo.sudo(api.tx.whitelists.addToWhitelist(...args)),
    // Oracle
    setPrice: api.tx.oracle.setPrice,
    // MM pools
    // mmCreateOrder: api.tx.mmPool.mmCreateOrder,
    // mmDeleteOrder: api.tx.mmPool.mmDeleteOrder,
    // mmDeposit: api.tx.mmPool.mmDeposit,
    // mmWithdraw: api.tx.mmPool.mmWithdraw,
    // mmSetManager: api.tx.mmPool.mmSetManager,
});
