import React from "react";
import { BG, Wrapper, Inner } from "./styled";

const Wallet: React.FC<{
  show: boolean;
  setShow: (val: boolean) => void;
}> = ({ children, show, setShow }) => {
  const ref = React.useRef(null);
  const [hideBG, setHideBG] = React.useState(true);

  React.useEffect(() => {
    if (show) {
      setHideBG(false);
    } else {
      const timeout = setTimeout(() => {
        setHideBG(true);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  return (
    <Wrapper>
      {!hideBG && <BG show={show} onClick={() => setShow(false)} />}
      <Inner ref={ref} isOpenWallet={show}>
        {children}
      </Inner>
    </Wrapper>
  );
};

export default Wallet;
