import type { ApiTypes, ApiBase } from "@polkadot/api/types";
import "../interfaces/augment-api";
import "../interfaces/augment-types";

export default <T extends ApiTypes>(api: Pick<ApiBase<T>, "consts">) => ({
  lockPeriod: api.consts.eqLockdrop?.lockPeriod,
  // margin call
  criticalMargin: api.consts.eqMarginCall.criticalMargin,
  initialMargin: api.consts.eqMarginCall.initialMargin,
  maintenanceMargin: api.consts.eqMarginCall.maintenanceMargin,
  maintenancePeriod: api.consts.eqMarginCall.maintenancePeriod,
});
