import { CrossChainTokens } from "@equilab/ui/lib/components/sidebar/types";
import assert from "assert";
import { getBridgeTokens, getNativeChains, NetworkName } from "./bridge";

export const getCrosschainCfg = (network: NetworkName): CrossChainTokens => {
  const tokens = getBridgeTokens(network);
  const chains = getNativeChains(network);
  const cfg: CrossChainTokens = {};

  for (const nativeChainId of Object.keys(tokens)) {
    const chain = chains[nativeChainId];
    assert(chain, "chain undefined");
    const chainTokens = tokens[nativeChainId];
    assert(chainTokens, "chain tokens undefined");

    for (const token of tokens[nativeChainId]) {
      if (!cfg[token.name]) {
        cfg[token.name] = [];
      }

      cfg[token.name].push({
        hostChainId: "1",
        nativeChainId,
        resourceId: token.resourceId,
        name: chain.name,
        addressPlaceholder: chain.addressPlaceholder ?? "",

        addressValidation:
          chain.addressValidation ?? (((s: string) => true) as any),

        chainSpec: {
          ethNetworkId: chain.ethNetId,
          ethWrap: token.wrap,
          ethBridgeAddress: chain.bridgeAddress,
          ethTokenAddress: token.tokenAddress,
          ethSpenderAddress: chain.spenderAddress,
        },
      });
    }
  }

  return Object.freeze(cfg);
};
