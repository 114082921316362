import "../interfaces/augment-api";
import "../interfaces/augment-types";
export default (api) => ({
    lockPeriod: api.consts.eqLockdrop?.lockPeriod,
    alpha: api.consts.bailsman.alpha,
    // margin call
    criticalMargin: api.consts.eqMarginCall.criticalMargin,
    initialMargin: api.consts.eqMarginCall.initialMargin,
    maintenanceMargin: api.consts.eqMarginCall.maintenanceMargin,
    maintenancePeriod: api.consts.eqMarginCall.maintenancePeriod,
});
