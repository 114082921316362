import { isEthAddress } from "@equilab/utils";

export type NetworkName = "mainnet" | "testnet" | "devnet";

export type ChainConfig = Record<
  string,
  {
    id: string;
    name: string;
    ethNetId: string;
    addressPlaceholder?: string;
    addressValidation?: (val: string) => boolean;
    bridgeAddress: string;
    spenderAddress: string;
  }
>;

export type BridgeTokens = Record<
  string /* nativeChainId */,
  Array<{
    name: string;
    tokenAddress: string;
    resourceId: string;
    wrap?: boolean;
  }>
>;

export const getNativeChains = (network: NetworkName): ChainConfig =>
  network !== "mainnet"
    ? {
        "2": {
          id: "2",
          name: "BSC Testnet",
          ethNetId: "97",
          addressPlaceholder: "0x".padEnd(42, "0"),
          addressValidation: isEthAddress,
          bridgeAddress:
            network === "devnet"
              ? "0xe481A5C4f3118c9A0D978FCA81d992D1736e76fc"
              : "0x25B20013DaC7B5d0fD2eA4f63cFcA91998748Ead",
          spenderAddress:
            network === "devnet"
              ? "0xA1338C2d0963312A613BbA9D691Bf04Dd10e13f7"
              : "0x77844c620a8C1667104B377234f29aAd15131452",
        },
        "0": {
          id: "0",
          name: "Rinkeby",
          ethNetId: "4",
          addressPlaceholder: "0x".padEnd(42, "0"),
          addressValidation: isEthAddress,
          bridgeAddress:
            network === "devnet"
              ? "0x57eaD6030148EdcC89d267E59c77F7eE39169ce1"
              : "0x47852B76e092B9848F8FE8AaCc38F7C69DF70781",
          spenderAddress:
            network === "devnet"
              ? "0x759A21Ce0408080a6E82805C07AE4A2cE9765432"
              : "0x4092030f42765B18578eDe218C1e33D31fd6531b",
        },
      }
    : {
        "2": {
          id: "2",
          name: "Binance Smart Chain",
          ethNetId: "56",
          addressPlaceholder: "0x".padEnd(42, "0"),
          addressValidation: isEthAddress,
          bridgeAddress: "0x233E3943Fd772d45ca80889862c7FBFB6B9306ee",
          spenderAddress: "0x777f57c808c0e44d572761f8c9068FBF0A11Fe5d",
        },
        "0": {
          id: "0",
          name: "Ethereum",
          ethNetId: "1",
          addressPlaceholder: "0x".padEnd(42, "0"),
          addressValidation: isEthAddress,
          bridgeAddress: "0x267c4d894db79a3023e266B84401e58f7434e1F1",
          spenderAddress: "0xe2a1D7C0c2ED4d3937bd6f93d9aCeA7498232F2F",
        },
      };

export const getBridgeTokens = (network: NetworkName): BridgeTokens =>
  network === "mainnet"
    ? {
        "0": [
          {
            name: "Crv",
            tokenAddress: "0xD533a949740bb3306d119CC777fa900bA034cd52",
            resourceId:
              "0x000000000000000000000000000000e54dd1f11e2fd2474af64f487e911b5900",
          },
          {
            name: "Dai",
            tokenAddress: "0x6b175474e89094c44da98b954eedeac495271d0f",
            resourceId:
              "0x000000000000000000000000000000B23802D01AEB6D2AF5F66BC49383D20D00",
          },
          {
            name: "Eth",
            tokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
            resourceId:
              "0x000000000000000000000000000000e7af8cdba234ffeeddccbbaa3458798700",
            wrap: true,
          },
          {
            name: "Eqd",
            tokenAddress: "0xF623Cfc0b2067CF0976C263e83C04cb06aAc32C7",
            resourceId:
              "0x000000000000000000000000000000074F3176C2CFBBC7BBA48D64535E071500",
          },
          {
            name: "Gens",
            tokenAddress: "0x9D9152874294aC0489eCf191376F48db99014112",
            resourceId:
              "0x0000000000000000000000000000007a05c51f15d366ac77bc86672166836100",
          },
          {
            name: "Usdc",
            tokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            resourceId:
              "0x000000000000000000000000000000f0eC6d6364BCE9Df4a3037c6D78BfE7900",
          },
          {
            name: "Usdt",
            tokenAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
            resourceId:
              "0x00000000000000000000000000000062CED3722C69D04D18C5CE5FA6EF9A8A00",
          },
          {
            name: "Wbtc",
            tokenAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
            resourceId:
              "0x0000000000000000000000000000002167B82CFD0CB1A577E338E65331E87F00",
          },
        ],
        "2": [
          {
            name: "Bnb",
            tokenAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            resourceId:
              "0x0000000000000000000000000000001DF9BD10DE107D2CE81A510EB158421000",
            wrap: true,
          },
          {
            name: "Busd",
            tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            resourceId:
              "0x0000000000000000000000000000004895B52441DDC4A70BF73BDA740A07D400",
          },
          {
            name: "Eqd",
            tokenAddress: "0xE7d62439Ebbe0059357B24971d927F1678ABfF2A",
            resourceId:
              "0x000000000000000000000000000000074F3176C2CFBBC7BBA48D64535E071500",
          },
          {
            name: "Gens",
            tokenAddress: "0x2CD14cbA3F26254beeD1d78158cd2B6F91809600",
            resourceId:
              "0x0000000000000000000000000000007a05c51f15d366ac77bc86672166836100",
          },
        ],
      }
    : {
        "0": [
          {
            name: "Crv",
            tokenAddress: "0x958D35B168230176dfF2Ca4Be0Eb1299943a9eBf",
            resourceId:
              "0x000000000000000000000000000000e54dd1f11e2fd2474af64f487e911b5900",
          },
          {
            name: "Dai",
            tokenAddress: "0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea",
            resourceId:
              "0x000000000000000000000000000000B23802D01AEB6D2AF5F66BC49383D20D00",
          },
          {
            name: "Eth",
            tokenAddress: "0x1715fb41d1b70Db7E14b84a902acd14B34F87c41",
            resourceId:
              "0x000000000000000000000000000000e7af8cdba234ffeeddccbbaa3458798700",
            wrap: true,
          },
          {
            name: "Eqd",
            tokenAddress: "0x61019ca5a8aBd1210DE22CF6F9EB4b1d5A514eFd",
            resourceId:
              "0x000000000000000000000000000000074F3176C2CFBBC7BBA48D64535E071500",
          },
          {
            name: "Gens",
            tokenAddress: "0xC61E9fC7684EF6273bc2F8bfdf63802713bD6bFb",
            resourceId:
              "0x0000000000000000000000000000007a05c51f15d366ac77bc86672166836100",
          },
          {
            name: "Usdc",
            tokenAddress: "0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b",
            resourceId:
              "0x000000000000000000000000000000f0eC6d6364BCE9Df4a3037c6D78BfE7900",
          },
          {
            name: "Usdt",
            tokenAddress: "0xc0FEB0F5AfEBdb8A9d6516aEB90516787415EeAc",
            resourceId:
              "0x00000000000000000000000000000062CED3722C69D04D18C5CE5FA6EF9A8A00",
          },
        ],
        "2": [
          {
            name: "Bnb",
            tokenAddress: "0xa48257a5592CFFe83CdE357e2748e0F9f48cFb58",
            resourceId:
              "0x0000000000000000000000000000001DF9BD10DE107D2CE81A510EB158421000",
            wrap: true,
          },
          {
            name: "Busd",
            tokenAddress: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
            resourceId:
              "0x0000000000000000000000000000004895B52441DDC4A70BF73BDA740A07D400",
          },
          {
            name: "Eqd",
            tokenAddress: "0x0668A8BAA5395b7260e18600d889aed86D3c44aC",
            resourceId:
              "0x000000000000000000000000000000074F3176C2CFBBC7BBA48D64535E071500",
          },
          {
            name: "Gens",
            tokenAddress: "0x839F5490C12878db4E9C41b1B0aCB4AA70F5b4CB",
            resourceId:
              "0x0000000000000000000000000000007a05c51f15d366ac77bc86672166836100",
          },
          {
            name: "Wbtc",
            tokenAddress: "0x7aFcb4a9D6e9911b673470A27516A80C3A958a1d",
            resourceId:
              "0x0000000000000000000000000000002167B82CFD0CB1A577E338E65331E87F00",
          },
        ],
      };
