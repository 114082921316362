export const currencyFromU64 = (u64) => {
    const bytes = [];
    let num = typeof u64 === "number"
        ? BigInt(u64)
        : typeof u64 === "bigint"
            ? u64
            : u64.toBigInt();
    do {
        bytes.unshift(Number(num % 256n));
        num = num / 256n;
    } while (num > 0);
    return Buffer.from(bytes).toString("utf8").toLowerCase();
};
export const u64FromCurrency = (currency) => {
    const buf = Buffer.from(currency.toLowerCase());
    const size = buf.length;
    return buf.reduce((val, digit, i) => {
        const exp = BigInt(size - 1 - i);
        return BigInt(val) + (256n ** exp) * BigInt(digit);
    }, 0n);
};
export const DEFAULT_DECIMALS = 9;
