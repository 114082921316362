import type {
  Asset,
  Currency,
  PricePoint,
} from "@equilab/api/genshiro/interfaces";

import { currencyFromU64 } from "@equilab/api/genshiro/util";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useApi } from "@equilab/ui/lib/contexts/polkadot/api";
import { useNetwork } from "@equilab/ui/lib/contexts/polkadot/network";
import { useBase } from "@equilab/ui/lib/contexts/substrate/base";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useRef } from "react";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { useConfig } from "../config";
import { fetchPipeline } from "./util";

export type CachedPrices = Array<{
  token: string;
  price: string;
}>;

export type ParsedPrices = Record<string, BigNumber>;

const PREC_64 = new BigNumber(10).pow(-9);

const validateCache = (raw: any): raw is CachedPrices => {
  if (!Array.isArray(raw)) {
    return false;
  }

  if (!raw.length) {
    return true;
  }

  const keys = Reflect.ownKeys(raw[0]);
  return ["token", "price"].every((key) => keys.includes(key));
};

const fromChain = (
  query: Array<Currency | Asset>,
  res: PricePoint[],
): CachedPrices =>
  res.map((point, i) => {
    const assetOrCurrency = query[i];

    const token = (assetOrCurrency as Asset)[0]
      ? currencyFromU64((assetOrCurrency as Asset)[0].toNumber())
      : (assetOrCurrency as Currency).toString();

    return { token, price: point.price.toString(10) };
  }, {});

const fromCached = (cached: CachedPrices) =>
  cached.reduce<ParsedPrices>(
    (prev, item) => ({ ...prev, [item.token]: PREC_64.times(item.price) }),
    {},
  );

const Context = createSimpleContext("prices-rx", of(undefined), () => {
  const base = useBase();
  const ref = useRef<() => void>();
  const { apiRx } = useApi<"Gens">();
  const { network, networks } = useNetwork();
  const { cacheUrls } = useConfig();

  useEffect(() => {
    ref.current = base.utilCrypto ? () => {} : base.create;
  }, [base]);

  const url = useMemo(() => {
    const net = network ?? networks[0];
    const baseUrl = cacheUrls[`${net.type ?? "Gens"}:${net.name ?? ""}`];

    if (!baseUrl) {
      return "http://localhost:3000";
    }

    if (!baseUrl) {
      return "http://localhost:3000";
    }

    return `${baseUrl}/api/v1/prices`;
  }, [network, networks, cacheUrls]);

  return useMemo(
    () =>
      (
        apiRx?.query.oracle.pricePoints.keys().pipe(
          switchMap((keys) => {
            const query = keys.map((k) => k.args[0]);

            return apiRx.query.oracle.pricePoints.multi(query).pipe(
              // @ts-ignore
              map(fromChain.bind(null, query)),
            );
          }),
        ) ??
        fetchPipeline({
          query: url,
          validate: validateCache,
          onError(e) {
            if (e instanceof Error) {
              console.error(e.message);
            }
          },
        })
      ).pipe(map((cached) => cached && fromCached(cached))),
    [apiRx, url],
  );
});

export const PricesProvider = Context.Provider;
export const usePrices = Context.useContext;
