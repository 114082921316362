import { memo } from "@polkadot/api-derive/util";
import { map } from "rxjs/operators";
import { currencyFromU64 } from "../../util";
export const names = (instanceId, api) => 
// @ts-ignore
memo(instanceId, () => 
// @ts-ignore
api.query.eqAssets.assets().pipe(
// @ts-ignore
map((raw) => raw.unwrap().map((asset) => currencyFromU64(asset.id)))));
