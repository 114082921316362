import type { Locale } from "../../locales";
import { useAccounts } from "@equilab/ui/lib/contexts/polkadot/connect";
import { useGetLang } from "@equilab/ui/lib/hooks";
import { cryptoWaitReady } from "@polkadot/util-crypto";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const Exchange = React.lazy(() => import("../exchange"));
const Manage = React.lazy(() => import("../manage"));
const Dashboard = React.lazy(() => import("../dashboard"));

const Routes: React.FC = ({ children }) => {
  const [ready, setReady] = React.useState(false);
  const { /*select, */ connect } = useAccounts();
  const lang = useGetLang<Locale>();

  React.useEffect(() => {
    Promise.all([cryptoWaitReady()]).then(([cryptoReady]) => {
      if (cryptoReady) {
        // select("cZfx34Bb2AsFpwP59jjeX2rT5e2u8hKkTz81FDRHbM8c611ew")
        setReady(true);
      }
    });
  }, []); // TODO move to upper level

  return ready ? (
    <React.Suspense fallback={children ?? <div />}>
      <Switch>
        <Route exact path="/:lang" component={Exchange} />

        <Route
          exact
          path="/:lang/manage/(deposit|withdraw)?"
          component={Manage}
        />

        <Route exact path="/:lang/dashboard" component={Dashboard} />

        <Redirect to={`/${lang}`} />
      </Switch>
    </React.Suspense>
  ) : (
    <>{children ?? <div />}</>
  );
};

export default Routes;
