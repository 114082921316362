import type {
  Asset,
  Currency,
  TotalAggregates,
  UserGroup,
} from "@equilab/api/genshiro/interfaces";

import { currencyFromU64 } from "@equilab/api/genshiro/util";
import { useApi } from "@equilab/ui/lib/contexts/polkadot/api";
import { useNetwork } from "@equilab/ui/lib/contexts/polkadot/network";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useBase } from "@equilab/ui/lib/contexts/substrate/base";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useRef } from "react";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { useConfig } from "../config";
import { fetchPipeline } from "./util";

export type CachedAggregates = Record<
  string,
  Record<
    string,
    {
      collateral: string;
      debt: string;
    }
  >
>;

export type ParsedAggregates = Record<
  string,
  Record<string, { collateral: BigNumber; debt: BigNumber }>
>;

const PREC_64 = new BigNumber(10).pow(-9);

const validateCache = (raw: any): raw is CachedAggregates => {
  if (!raw) {
    return false;
  }

  const defaultToken = "Gens"; // TODO

  if (!raw?.Balances?.[defaultToken]) {
    return false;
  }

  const keys = Reflect.ownKeys(raw?.Balances?.[defaultToken]);
  return ["debt", "collateral"].every((key) => keys.includes(key));
};

const fromChain = (
  // TODO
  keys: [UserGroup, Asset | Currency][],
  aggregates: TotalAggregates[],
): CachedAggregates =>
  keys.reduce<CachedAggregates>((prev, key, i) => {
    const aggr = aggregates[i];
    const [group, assetOrCurrency] = key;
    const byGroup = prev[group.toString()] ?? {};
    const token = (assetOrCurrency as Asset)[0]
      ? currencyFromU64((assetOrCurrency as Asset)[0].toNumber())
      : (assetOrCurrency as Currency).toString();

    return {
      ...prev,
      [group.toString()]: {
        ...byGroup,
        [token]: {
          collateral: aggr.collateral.toString(10),
          debt: aggr.debt.toString(10),
        },
      },
    };
  }, {}); // TODO

const fromCached = (cached: CachedAggregates) => {
  const groups = Reflect.ownKeys(cached).filter(
    (group): group is string => typeof group === "string",
  );

  return groups.reduce<ParsedAggregates>((prev, group) => {
    const byGroup = cached[group];

    const tokens = Reflect.ownKeys(byGroup).filter(
      (group): group is string => typeof group === "string",
    );

    return {
      ...prev,
      [group]: tokens.reduce<
        Record<string, { collateral: BigNumber; debt: BigNumber }>
      >((prev, token) => {
        const byToken = byGroup[token];

        const [collateral, debt] = (["collateral", "debt"] as Array<
          "collateral" | "debt"
        >).map((key) => PREC_64.times(byToken[key]));

        return { ...prev, [token]: { collateral, debt } };
      }, {}),
    };
  }, {});
};

const Context = createSimpleContext("aggregates-rx", of({}), () => {
  const base = useBase();
  const ref = useRef<() => void>();
  const { apiRx } = useApi<"Gens">();
  const { network, networks } = useNetwork();
  const { cacheUrls } = useConfig();

  useEffect(() => {
    ref.current = base.utilCrypto ? () => {} : base.create;
  }, [base]);

  const url = useMemo(() => {
    const net = network ?? networks[0];
    const baseUrl = cacheUrls[`${net.type ?? "Gens"}:${net.name ?? ""}`];

    if (!baseUrl) {
      return "http://localhost:3000";
    }

    return `${baseUrl}/api/v1/aggregates`;
  }, [network, networks, cacheUrls]);

  return useMemo(
    () =>
      (
        apiRx?.query.eqAggregates.totalUserGroups
          // @ts-ignore
          .keys()
          .pipe(
            switchMap((keys) => {
              const query = keys.map((k) => k.args);

              return apiRx.query.eqAggregates.totalUserGroups
                .multi(query)
                // @ts-expect-error
                .pipe(map(fromChain.bind(null, query)));
            }),
          ) ??
        fetchPipeline({
          query: url,
          validate: validateCache,

          onError: () => {
            if (ref.current) {
              ref.current();
            }
          },
        })
      ).pipe(map((res) => res && fromCached(res!))),
    [apiRx, url],
  );
});

export const useAggregates = Context.useContext;
export const AggregatesProvider = Context.Provider;
