import { PortableRegistry, TypeRegistry } from "@polkadot/types";
import type { FunctionMetadataLatest } from "@polkadot/types/interfaces";
import { createCallFunction } from "@polkadot/types/metadata/decorate/extrinsics";
import type { CallFunction } from "@polkadot/types/types";
import type { AnyTuple, Codec } from "@polkadot/types-codec/types";

export class LiteRegistry extends TypeRegistry {
  constructor(...args: any[]) {
    super(...args);

    this.setLookup(
      new PortableRegistry(this, {
        types: [
          {
            id: 0,
            type: {
              def: {
                primitive: "U32",
              },
            },
          },
          {
            id: 1,
            type: {
              def: {
                primitive: "str",
              },
            },
          },
          {
            id: 10,
            type: {
              path: ["generic", "Call"],
              params: [
                {
                  name: "T",
                  type: null,
                },
              ],
              def: {
                variant: {
                  variants: [
                    {
                      name: "generic",
                      fields: [
                        {
                          name: "p1",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p2",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p3",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p4",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p5",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p6",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p7",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                        {
                          name: "p8",
                          type: 0,
                          typeName: "U32",
                          docs: [],
                        },
                      ],
                      index: 0,
                      docs: ["some line"],
                    },
                  ],
                },
              },
              docs: [
                "Contains one variant per dispatchable that can be called by an extrinsic.",
              ],
            },
          },
        ],
      } as unknown as Uint8Array),
    );
  }

  getFallback = (
    arr: Uint8Array,
  ): Codec & { section: string; method: string } => {
    return Object.assign(this.createType("Vec<u8>", arr), {
      section: `0x${arr[0]}`,
      method: `0x${arr[0]}`,
    });
  };

  findMetaCall(
    callIndex: Uint8Array,
  ): CallFunction<AnyTuple, FunctionMetadataLatest> {
    try {
      return super.findMetaCall(callIndex);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.stack);
      }

      return createCallFunction(
        this,
        this.lookup,
        this.lookup.getSiType(10).def.asVariant.variants[0],
        Buffer.from(callIndex).toString("hex"),
        1,
      );
    }
  }

  /*
  tryGuessEventVariant = (eventIndex: Uint8Array, numFields = 1) => {
    const variant =
      this.lookup.getSiType(11).def.asVariant.variants[numFields - 1];

    if (!variant) {
      throw new Error("variants exceed");
    }

    try {
      const meta = this.createType(
        "EventMetadataLatest",
        Object.assign({}, variant, { args: [] }),
      );

      return class extends GenericEventData {
        constructor(registry: Registry, value: Uint8Array) {
          super(
            registry,
            value,
            // @ts-ignore
            meta,
            Buffer.from(eventIndex).toString("hex"),
            "event",
          );
        }
      };
    } catch {
      return false;
    }
  };

  findMetaEvent = (eventIndex: Uint8Array): CodecClass<GenericEventData> => {
    try {
      return super.findMetaEvent(eventIndex);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.stack);
      }

      for (let numFields = 1; ; ) {
        const res = this.tryGuessEventVariant(eventIndex, numFields++);

        if (res) {
          return res;
        }
      }
    }
  };
  */
}
