import type { u64 } from "@polkadot/types";
const capitalize = (str: string) =>
  str.slice(0, 1).toUpperCase() + str.slice(1);

export interface BNLike {
  toBigNumber(): bigint;
  toNumber(): number;
  toString(): string;
}

/** @deprecated use only on genshiro */
export const tokenFromAsset = ({ 0: u64 }: { 0: bigint | number | u64 }) => {
  const bytes: number[] = [];

  let num =
    typeof u64 === "number"
      ? BigInt(u64)
      : typeof u64 === "bigint"
      ? u64
      : u64.toBigInt();

  do {
    bytes.unshift(Number(num % 256n));
    num = num / 256n;
  } while (num > 0);

  return capitalize(Buffer.from(bytes).toString("utf8"));
};

export const assetFromToken = (currency: string) => {
  const buf = Buffer.from(currency.toLowerCase());
  const size = buf.length;

  return {
    0: buf.reduce(
      (val, digit, i) => val + Math.pow(256, size - 1 - i) * digit,
      0,
    ),
  };
};
