import type { GetProps } from "../util";
import { ApiProvider } from "@equilab/ui/lib/contexts/polkadot/api";
import { AssetsProvider as CompatAssetsProvider } from "@equilab/ui/lib/contexts/polkadot/assets";
import { BalanceProvider } from "@equilab/ui/lib/contexts/polkadot/balance";
import { ConnectProvider } from "@equilab/ui/lib/contexts/polkadot/connect";
import { NetworkProvider } from "@equilab/ui/lib/contexts/polkadot/network";
import { StatProvider } from "@equilab/ui/lib/contexts/polkadot/stat";
import { SubAccountProvider } from "@equilab/ui/lib/contexts/polkadot/sub-account";
import { BaseProvider, useBase } from "@equilab/ui/lib/contexts/substrate/base";
import React from "react";
import { ChainIdProvider } from "./api/chain-id";
import { CurveApiProvider } from "./api/curve";
import { CurveHistoryProvider } from "./api/curve-history";
import { ConfigConsumer, ConfigProvider } from "./config";
import { ConvertProvider } from "./features/convert";
import { CurveProvider } from "./features/curve";
import { ParamsProvider } from "./features/params";
import { AggregatesProvider } from "./observables/aggregates";
import { AssetsProvider } from "./observables/assets";
import { PricesProvider } from "./observables/prices";
import { assetsFilter } from "../util";

// NB: compose order matters
const ChainCommonProvider: React.FC<
  Omit<
    GetProps<typeof NetworkProvider> & GetProps<typeof ConnectProvider>,
    "useBase"
  >
> = ({ children, ...props }) => (
  <BaseProvider init>
    <ConfigProvider>
      <ConfigConsumer>
        {(config) => (
          <NetworkProvider networks={config.networks} lsKey={props.lsKey}>
            <ApiProvider useBase={useBase}>
              <CompatAssetsProvider>
                <ConnectProvider name={props.name} useBase={useBase}>
                  <SubAccountProvider useBase={useBase}>
                    <BalanceProvider>
                      <StatProvider>{children}</StatProvider>
                    </BalanceProvider>
                  </SubAccountProvider>
                </ConnectProvider>
              </CompatAssetsProvider>
            </ApiProvider>
          </NetworkProvider>
        )}
      </ConfigConsumer>
    </ConfigProvider>
  </BaseProvider>
);

const BackendApiProvider: React.FC<GetProps<typeof CurveApiProvider>> = ({
  children,
  ...props
}) => (
  <ChainIdProvider apiBaseUrl={props.apiBaseUrl}>
    <CurveApiProvider {...props}>
      <CurveHistoryProvider {...props}>{children}</CurveHistoryProvider>
    </CurveApiProvider>
  </ChainIdProvider>
);

const FeatureProvider: React.FC<GetProps<typeof CurveProvider>> = ({
  children,
  ...props
}) => (
  <AggregatesProvider>
    <AssetsProvider>
      <PricesProvider>
        <ConvertProvider>
          <ParamsProvider>
            <CurveProvider {...props}>{children}</CurveProvider>
          </ParamsProvider>
        </ConvertProvider>
      </PricesProvider>
    </AssetsProvider>
  </AggregatesProvider>
);

export const Provider: React.FC<
  GetProps<typeof ChainCommonProvider> &
    GetProps<typeof BackendApiProvider> &
    GetProps<typeof FeatureProvider>
> = ({ children, ...props }) => (
  <ChainCommonProvider
    networks={props.networks}
    lsKey={props.lsKey}
    name={props.name}
  >
    <BackendApiProvider apiBaseUrl={props.apiBaseUrl}>
      <FeatureProvider poolInfo={props.poolInfo}>{children}</FeatureProvider>
    </BackendApiProvider>
  </ChainCommonProvider>
);
