import type { EQExtrinsic } from "@equilab/utils/lib/types/apiv2";
import type { ApiConfig, CurveExchangeEvent } from "./types";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useAccounts } from "@equilab/ui/lib/contexts/polkadot/connect";
import { useNetwork } from "@equilab/ui/lib/contexts/polkadot/network";
import { useFetch } from "@equilab/ui/lib/hooks/common";
import { encodeAddress } from "@polkadot/util-crypto";
import BigNumber from "bignumber.js";
import { useCallback, useMemo } from "react";

import { useChainId } from "./chain-id";

import {
  eventsPredicate,
  extrinsicsPredicate,
  parsePayload,
  notEmpty,
} from "./util";

const Context = createSimpleContext(
  "api-curve-history",
  {
    curveHistory: undefined,
    curveExchangeEvents: [] as CurveExchangeEvent[],
    curveHistoryUpdate: () => Promise.reject(new Error("not implemented")),
    curveExchangeUpdate: () => Promise.reject(new Error("not implemented")),
  },
  ({ apiBaseUrl }: ApiConfig) => {
    const { network } = useNetwork();
    const { account } = useAccounts();
    const chainId = useChainId();

    const curveAccountEvents = useFetch({
      query:
        account?.pub && chainId
          ? `${apiBaseUrl}/events?chainId=${chainId}&section=curveAmm&method=[AddLiquidity,RemoveLiquidity,RemoveLiquidityImbalance,RemoveLiquidityOne]&acc1=${encodeAddress(
              "0x" + account.pub,
              network?.ss58,
            )}`
          : undefined,
      method: "get",
      parsePayload,
      predicate: eventsPredicate,
    });

    const curveRemoveLiquidityExtrinsic = useFetch({
      query:
        account?.pub && chainId
          ? `${apiBaseUrl}/extrinsics?chainId=${chainId}&section=curveAmm&method=removeLiquidity&signer=${encodeAddress(
              "0x" + account.pub,
              network?.ss58,
            )}`
          : undefined,
      method: "get",
      parsePayload,
      predicate: extrinsicsPredicate,
    });

    const curveIndex = useMemo(() => {
      return curveRemoveLiquidityExtrinsic.data?.reduce(
        (prev: Record<number, EQExtrinsic[]>, val) => {
          const { blockNumber } = val;

          return {
            ...prev,
            [blockNumber]: [...(prev[blockNumber] ?? []), val],
          };
        },
        {},
      );
    }, [curveRemoveLiquidityExtrinsic.data]);

    const curveHistory = useMemo(() => {
      return curveAccountEvents.data
        ?.sort((a, b) => b.blockNumber - a.blockNumber)
        .map((event) => {
          // const data = JSON.parse(event.rawData);
          // const [, rawPool, amount] = data;
          let amounts: undefined | Array<undefined | BigNumber>;
          let lpTotal: undefined | BigNumber;
          let lpChange: undefined | BigNumber;
          const poolId = event.long1;

          switch (event.method) {
            case "AddLiquidity": {
              amounts = undefined; // FIXME: no event data in db

              lpTotal = new BigNumber(event.balance2 ?? 0);
              lpChange = new BigNumber(event.balance3 ?? 0);
              break;
            }

            case "RemoveLiquidityOne": {
              const tokenIndex = event.long2 ?? 0;

              amounts = [];
              amounts[tokenIndex] = new BigNumber(event.balance2 ?? 0);
              lpTotal = new BigNumber(event.balance3 ?? 0);
              lpChange = new BigNumber(event.balance1 ?? 0).negated();
              break;
            }

            case "RemoveLiquidity": {
              amounts = undefined; // FIXME: no event data in db

              lpTotal = new BigNumber(event.balance1 ?? 0);
              lpChange = new BigNumber(event.balance2 ?? 0);

              break;
            }

            case "RemoveLiquidityImbalance": {
              amounts = undefined; // FIXME: no event data in db

              lpTotal = new BigNumber(event.balance2 ?? 0);
              lpChange = new BigNumber(event.balance3 ?? 0).negated();
              break;
            }

            default:
              throw new Error(
                `Unknown event: ${event.section}.${event.method}`,
              );
          }

          if (!amounts) {
            throw new Error("Incorrect amounts when parsing curve events");
          }

          return {
            createdAt: event.createdAt,
            blockNumber: event.blockNumber,
            poolId,
            lpChange,
            lpTotal,
            amounts,
            lpPrice: lpChange?.div(
              amounts.reduce(
                (prev: BigNumber, val) => prev.plus(val ?? 0),
                new BigNumber(0),
              ),
            ),
          };
        });
    }, [curveAccountEvents.data]);

    const curveExchangeFetch = useFetch({
      query:
        account?.pub && chainId
          ? `${apiBaseUrl}/events?chainId=${chainId}&section=curveAmm&method=TokenExchange&acc1=${encodeAddress(
              "0x" + account.pub,
              network?.ss58,
            )}`
          : undefined,
      method: "get",
      parsePayload,
      predicate: eventsPredicate,
    });

    const curveExchangeEvents = useMemo((): CurveExchangeEvent[] => {
      const parsed = curveExchangeFetch?.data
        ?.map(
          ({
            long1,
            long2,
            long3,
            balance1,
            balance2,
            balance3,
            createdAt,
            extrinsic,
          }) => {
            if (
              !long1 ||
              !long2 ||
              !long3 ||
              !balance1 ||
              !balance2 ||
              !balance3
            )
              return undefined;

            return {
              poolId: long1,
              poolTokenSent: long2,
              poolAmountSent: new BigNumber(balance1),
              poolTokenReceived: long3,
              poolAmountReceived: new BigNumber(balance2),
              date: createdAt,
              fee: new BigNumber(balance3),
              trxFee: new BigNumber(extrinsic?.fee ?? 0),
            };
          },
        )
        .filter(notEmpty);
      return parsed ?? [];
    }, [curveExchangeFetch]);

    const curveExchangeUpdate = useCallback(() => {
      void curveExchangeFetch.exec();
    }, [curveExchangeFetch.exec]);

    const curveHistoryUpdate = useCallback(
      () =>
        Promise.all([
          curveRemoveLiquidityExtrinsic.exec(),
          curveAccountEvents.exec(),
          curveExchangeFetch.exec(),
        ]),
      [
        curveRemoveLiquidityExtrinsic.exec,
        curveAccountEvents.exec,
        curveExchangeFetch.exec,
      ],
    );

    return {
      curveHistory,
      curveHistoryUpdate,
      curveExchangeEvents,
      curveExchangeUpdate,
    };
  },
);

export const useCurveHistory = Context.useContext;
export const CurveHistoryProvider = Context.Provider;
