import { useBalance } from "@equilab/ui/lib/contexts/polkadot/balance";
import React from "react";
import { useConvert } from "../contexts/features/convert";

/** @deprecated */
const Compat: React.FC = () => {
  const { setConvert } = useBalance();
  const convert = useConvert();

  React.useEffect(() => {
    setConvert(() => convert);
  }, [setConvert, convert]);

  return null;
};

export default Compat;
