import type { PoolInfo } from "@equilab/api/genshiro/interfaces";
import type { SubmittableExtrinsic } from "@polkadot/api/types";
import type { Option } from "@polkadot/types";
import type { ISubmittableResult } from "@polkadot/types/types";
import type { FC } from "react";
import { currencyFromU64 } from "@equilab/api/eq-next/util";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useApi } from "@equilab/ui/lib/contexts/polkadot/api";
import { useStat } from "@equilab/ui/lib/contexts/polkadot/stat";
import { useAsyncEffect } from "@equilab/ui/lib/hooks/async";
import { useObsState } from "@equilab/ui/lib/hooks/rxjs";
import BigNumber from "bignumber.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { wrapExtrinsic } from "../../util";
import { useCurveApi } from "../api/curve";
import { useAggregates } from "../observables/aggregates";

interface Pool {
  id: number;
  asset: string;
  assets: string[];
  fee: number;
  adminFee: number;
  balances: BigNumber[];
  totalBalance: BigNumber;
  icon?: FC;
  ticker?: string;
  title?: string;
}

export interface WithdrawFlags {
  isOne?: boolean;
  isImbalance?: boolean;
}

interface IsOneWithdrawFlag extends WithdrawFlags {
  isOne: true;
}

interface IsImbalanceWithdrawFlag extends WithdrawFlags {
  isImbalance: true;
}

const PREC_64 = new BigNumber(10).pow(-9);

export const isOne = (flags: WithdrawFlags): flags is IsOneWithdrawFlag =>
  !!flags.isOne;

export const isImbalance = (
  flags: WithdrawFlags,
): flags is IsImbalanceWithdrawFlag => !!flags.isImbalance;

const useCurveInner = ({
  poolInfo,
}: {
  poolInfo: Record<
    number | "_default",
    { icon?: FC; ticker?: string; title?: string }
  >;
}) => {
  const { curvePoolCreated, curveTokenExchange } = useCurveApi();
  const { api } = useApi<"Gens">();
  const aggregates$ = useAggregates();
  const aggregates = useObsState(aggregates$);
  const { block } = useStat();
  const [pools, setPools] = useState<Pool[]>();
  const time = useRef<number[]>();
  const [curvePoolReady, setCurvePoolReady] = useState(false);

  useEffect(() => {
    const times = pools?.map(
      ({ id }) => (block ?? 0) - (curvePoolCreated?.[id] ?? block ?? 0),
    );

    if (times?.every((t) => t > 0)) {
      setCurvePoolReady(true);
    }

    time.current = times;
  }, [block, pools, curvePoolCreated]);

  useAsyncEffect(async () => {
    if (api) {
      const keys: string[][] = (await api.query.getPool.keys()).map((key) =>
        key.toHuman(),
      ) as any;

      const unsub = await api.query.getPool.multi(
        keys,
        (pools: Option<PoolInfo>[]) => {
          setPools(
            pools
              .filter((opt, i, arr) => !opt.isNone)
              .map((pool, i) => {
                const id = Number(keys[i][0]);

                const {
                  owner,
                  pool_asset,
                  assets,
                  amplification,
                  fee: rawFee,
                  admin_fee,
                  balances: rawBalances,
                } = pool.unwrap();

                const balances = rawBalances.map((balance) =>
                  PREC_64.times(balance.toString(10)),
                );

                const totalBalance = balances.reduce(
                  (prev, b) => prev.plus(b),
                  new BigNumber(0),
                );

                const fee = rawFee.toNumber() / 1000000;

                return {
                  id,
                  asset: currencyFromU64(pool_asset[0].toNumber()),
                  assets: assets.map((id) => currencyFromU64(id[0].toNumber())),
                  fee: fee,
                  adminFee: (admin_fee.toNumber() / 1000000) * fee,
                  balances,
                  totalBalance,
                  ...(poolInfo[id] ?? poolInfo._default),
                };
              }),
          );
        },
      );

      return () => {
        unsub();
      };
    }
  }, [api, poolInfo]);

  const virtualPrices = useMemo(
    () =>
      pools
        ?.map((p) =>
          p.totalBalance.div(
            aggregates?.Balances?.[p.asset]?.collateral ?? p.totalBalance,
          ),
        )
        .map((p) => (p.isFinite() ? p : new BigNumber(1))),
    [pools, aggregates],
  );

  const rewards = useMemo(
    () =>
      curvePoolReady
        ? virtualPrices?.map((price, i) => {
            // TODO after a year has passed
            /*
            const reward = time.current?.[i]
              ? Math.pow(
                  price.toNumber(),
                  Math.floor((365 * 24 * 60 * 60) / 6 / time.current[i]),
                ) - 1
              : 0;
              */

            const reward = price?.minus(1).abs().toNumber();
            return reward;
          })
        : undefined,
    [virtualPrices, curvePoolReady],
  );

  const volumes = useMemo(() => {
    if (pools && curveTokenExchange) {
      const res: BigNumber[] = [];
      const DAY_MS = 1000 * 60 * 60 * 24;
      pools.forEach((p) => {
        const volume = curveTokenExchange
          .filter((e) => Number(e.long1) === p.id)
          .sort((a, b) => b.blockNumber - a.blockNumber)
          .filter((el, _, arr) => {
            const lastExchangedAt = new Date(arr[0].createdAt).getTime();
            const currExchangedAt = new Date(el.createdAt).getTime();
            return lastExchangedAt - currExchangedAt <= DAY_MS;
          })
          .reduce((res, el) => {
            const sent = el.balance1 ?? 0;
            const received = el.balance2 ?? 0;
            return res.plus(sent).minus(received);
          }, new BigNumber(0, 10));

        res.push(BigNumber.max(0, volume));
      });

      return res;
    }
  }, [curveTokenExchange, pools]);

  // Curve Exchange
  const getExchangeExtrinsic = useCallback(
    (poolId: number, i: number, j: number, amount: number) => {
      if (api) {
        try {
          return wrapExtrinsic(
            api,
            api.tx.curveExchange(poolId, i, j, amount, 0),
          );
        } catch (err) {
          console.error(err);
        }
      }
    },
    [api],
  );

  // Curve Deposit
  const getDepositExtrinsic = useCallback(
    (poolId: number, amounts: string[]) => {
      if (api) {
        try {
          return wrapExtrinsic(api, api.tx.curveAdd(poolId, amounts, 0));
        } catch (err) {
          console.error(err);
        }
      }
    },
    [api],
  );

  // Curve Withdraw
  const getWithdrawExtrinsic = useCallback(
    <T extends WithdrawFlags>(
      flags: T,
      amounts: T extends IsOneWithdrawFlag
        ? { index: number; amount: string }
        : T extends IsImbalanceWithdrawFlag
        ? string[]
        : string,
      poolId: number,
      total: number,
    ) => {
      if (api) {
        try {
          let ex: SubmittableExtrinsic<"promise", ISubmittableResult>;

          if (isOne(flags)) {
            const { index, amount } = amounts as {
              index: number;
              amount: string;
            };

            if (index >= total) {
              throw new Error("index out of range");
            }

            ex = api.tx.curveRemoveOne(poolId, amount, index, 0);
          } else if (isImbalance(flags)) {
            const amnt = amounts as string[];

            const amountArr = Array.from(Array(total)).map((_, i) =>
              amnt[i] ? amnt[i] : "0",
            );

            ex = api.tx.curveRemoveImbalance(
              poolId,
              amountArr,
              amnt
                .reduce(
                  (prev: BigNumber, val) => prev.plus(val),
                  new BigNumber(0),
                )
                .times(2) // TODO calc
                .toFixed(0),
            );
          } else {
            ex = api.tx.curveRemove(
              poolId,
              amounts as string,
              Array.from(Array(total)).map(() => "0"),
            );
          }

          return wrapExtrinsic(api, ex, {
            debugVal: JSON.stringify({ flags, amounts, poolId, total }),
          });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [api],
  );

  return {
    pools,
    virtualPrices,
    rewards,
    volumes,
    getExchangeExtrinsic,
    getDepositExtrinsic,
    getWithdrawExtrinsic,
  };
};

const Context = createSimpleContext(
  "curve",
  {
    pools: undefined,
    virtualPrices: undefined,
    rewards: undefined,
    getExchangeExtrinsic() {
      throw new Error();
    },
    getDepositExtrinsic() {
      throw new Error();
    },
    getWithdrawExtrinsic() {
      throw new Error();
    },
    volumes: [],
  },
  useCurveInner,
);

export const CurveProvider = Context.Provider;
export const useCurve = Context.useContext;
