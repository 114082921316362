import type { SubstNetwork } from "@equilab/ui/lib/contexts/polkadot/network";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useMemo } from "react";

const cacheUrls: Record<string, string | undefined> = {
  "Eq:Mainnet": "https://middleware-api.equilibrium.io",
  "Gens:Mainnet": "https://middleware-api.genshiro.io",
  "Gens:Testnet": "https://middleware-api-testnet.genshiro.io",
};

const networks: Record<"Eq" | "Gens", SubstNetwork[]> = {
  Eq: [
    {
      type: "Eq",
      node: ["wss://node.equilibrium.io"],
      name: "Mainnet",
      ss58: 68,
    },
  ],
  Gens: [
    {
      type: "Gens",
      node: [
        "wss://node1.genshiro.io",
        "wss://node2.genshiro.io",
        "wss://node3.genshiro.io",
      ],
      name: "Mainnet",
      ss58: 67,
    },
    {
      type: "Gens",
      node: ["wss://testnet.genshiro.io"],
      name: "Testnet",
      ss58: 67,
    },
    /*{ // DEVNET
      type: "Gens",
      node: ["wss://devnet.genshiro.io"],
      name: "Testnet",
      ss58: 67,
    },*/
  ],
};

const product =
  /* process.env.REACT_APP_THEME === "Genshiro" ? */ "Gens"; /* : "Eq"*/

const Context = createSimpleContext(
  "config",
  { cacheUrls: {}, networks: [], product: "Gens" },
  () => {
    return useMemo(
      () => ({
        cacheUrls,
        networks: [networks[product][0]],
        product,
      }),
      [],
    );
  },
);

export const ConfigConsumer = Context.Consumer;
export const ConfigProvider = Context.Provider;
export const useConfig = Context.useContext;
