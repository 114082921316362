import type { EQExtrinsic } from "@equilab/utils/lib/types/apiv2";
import BigNumber from "bignumber.js";

interface EQEvent {
  createdAt: string;
  blockNumber: number;
  section: string;
  method: string;
  accountId1: string | null;
  accountId2: string | null;
  balance1: string | null;
  balance2: string | null;
  balance3: string | null;
  long1: number | null;
  long2: number | null;
  long3: number | null;
  currency1: string | null;
  transferReason1: string | null;
  extrinsic?: EQExtrinsic;
}

const SI = [
  { power: -24, text: "yocto", value: "y" },
  { power: -21, text: "zepto", value: "z" },
  { power: -18, text: "atto", value: "a" },
  { power: -15, text: "femto", value: "f" },
  { power: -12, text: "pico", value: "p" },
  { power: -9, text: "nano", value: "n" },
  { power: -6, text: "micro", value: "µ" },
  { power: -3, text: "milli", value: "m" },
  { power: 0, text: "Unit", value: "-" }, // position 8
  { power: 3, text: "Kilo", value: "k" },
  { power: 6, text: "Mill", value: "M" }, // Mega, M
  { power: 9, text: "Bill", value: "B" }, // Giga, G
  { power: 12, text: "Tril", value: "!T" }, // Tera, T // TODO
  { power: 15, text: "Peta", value: "P" },
  { power: 18, text: "Exa", value: "E" },
  { power: 21, text: "Zeta", value: "Z" },
  { power: 24, text: "Yotta", value: "Y" },
];

export const parsePayload = (raw: any) => JSON.parse(raw);

export const eventsPredicate = (raw: any): raw is Array<EQEvent> =>
  Array.isArray(raw) &&
  (!raw.length || // or check all items? TODO
    (typeof raw[0].blockNumber === "number" &&
      typeof raw[0].rawData === "string" &&
      typeof raw[0].section === "string" &&
      typeof raw[0].method === "string" &&
      (typeof raw[0].accountId1 === "string" ||
        Object.is(raw[0].accountId1, null)) &&
      (typeof raw[0].accountId2 === "string" ||
        Object.is(raw[0].accountId2, null)) &&
      (typeof raw[0].balance1 === "number" ||
        Object.is(raw[0].balance1, null)) &&
      (typeof raw[0].balance2 === "number" ||
        Object.is(raw[0].balance2, null)) &&
      (typeof raw[0].balance3 === "number" ||
        Object.is(raw[0].balance3, null)) &&
      (typeof raw[0].long1 === "number" || Object.is(raw[0].long1, null)) &&
      (typeof raw[0].long2 === "number" || Object.is(raw[0].long2, null)) &&
      (typeof raw[0].long3 === "number" || Object.is(raw[0].long3, null)) &&
      (typeof raw[0].currency1 === "string" ||
        Object.is(raw[0].currency1, null)) &&
      (typeof raw[0].transferReason1 === "string" ||
        Object.is(raw[0].transferReason1, null))));
// (typeof raw[0].unknown1 === "string" ||
//   (Object.is(raw[0].unknown1, null) &&
//     (typeof raw[0].transferReason1 === "string" ||
//       Object.is(raw[0].transferReason1, null))))));

export const extrinsicsPredicate = (raw: any): raw is Array<EQExtrinsic> =>
  Array.isArray(raw) &&
  (!raw.length ||
    (typeof raw[0].blockNumber === "number" &&
      typeof raw[0].section === "string" &&
      typeof raw[0].method === "string" &&
      typeof raw[0].rawArgs === "string"));

export const fromSI = (amount: string) => {
  try {
    const [val, unit] = amount.split(" ");
    const power = SI.find(({ value }) => value === unit?.[0])?.power ?? 0;
    return new BigNumber(10).pow(power).times(val ?? "0");
  } catch {
    return new BigNumber(0);
  }
};

export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};
