import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useState } from "react";

const Context = createSimpleContext(
  "params",
  {
    poolIndex: 0,
    setPoolIndex() {
      throw new Error("not implemented");
    },
  },
  () => {
    const [poolIndex, setPoolIndex] = useState(0);

    return {
      poolIndex,
      setPoolIndex,
    };
  },
);

export const useParams = Context.useContext;
export const ParamsProvider = Context.Provider;
