import { themedIcon } from "@equilab/ui/lib/theme";
import { LPT } from "@equilab/ui/lib/components/icons/assets";
import Icons from "@equilab/ui/lib/assets";
import { ReactComponent as BUSD } from "../../assets/busd.svg";
import { ReactComponent as DAI } from "../../assets/dai.svg";
import { ReactComponent as USDC } from "../../assets/usdc.svg";
import { ReactComponent as USDT } from "../../assets/usdt.svg";
import { ReactComponent as EQD } from "../app/eqd.svg";
import { ReactComponent as BNB } from "../../assets/bnb.svg";
import { ReactComponent as ETH } from "../../assets/eth.svg";
import { ReactComponent as WBTC } from "../../assets/wbtc.svg";
import { ReactComponent as GENS } from "../../assets/gens.svg";
import { ReactComponent as CRV } from "../../assets/crv.svg";

const pools = {
  0: {
    icon: LPT,
    ticker: "USD",
    title: "Stable",
  },
  1: {
    icon: LPT.bind(null, { num: 1 }),
    ticker: "USD",
    title: "Stable",
  },
};

const icons = [
  ["Usd", themedIcon(Icons.Usd)],
  ["Busd", themedIcon(BUSD)],
  ["Dai", themedIcon(DAI)],
  ["Usdc", themedIcon(USDC)],
  ["Usdt", themedIcon(USDT)],
  ["Eqd", themedIcon(EQD)],
  ["Bnb", themedIcon(BNB)],
  ["Eth", themedIcon(ETH)],
  ["Wbtc", themedIcon(WBTC)],
  ["Gens", themedIcon(GENS)],
  ["Crv", themedIcon(CRV)],
  ["Lpt0", pools[0].icon],
  // ["Lpt1", pools[1].icon],
];

export const getIconsList = (tokens: string[]): typeof icons => {
  return tokens.reduce((acc: typeof icons, el) => {
    const themedIcon = icons.find(([token]) => token === el);

    if (themedIcon) return [...acc, themedIcon];

    const lptRe = /(Lpt)(\d*)/g.exec(el);

    if (lptRe && lptRe.length === 3) {
      return [...acc, [el, LPT.bind(null, { num: Number(lptRe[2]) })]];
    }

    return acc;
  }, []);
};
