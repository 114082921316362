import type { ApiConfig } from "./types";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useFetch } from "@equilab/ui/lib/hooks";
import { useMemo } from "react";
import { useChainId } from "./chain-id";
import { eventsPredicate, parsePayload } from "./util";

const Context = createSimpleContext(
  "api-curve",
  { curvePoolCreated: undefined, curveTokenExchange: undefined },
  ({ apiBaseUrl }: ApiConfig) => {
    const chainId = useChainId();

    // Curve pool creation
    const curveCreatePool = useFetch({
      query: chainId
        ? `${apiBaseUrl}/events?chainId=${chainId}&section=curveAmm&method=CreatePool`
        : undefined,
      method: "get",
      parsePayload,
      predicate: eventsPredicate,
    });

    const curvePoolCreated = useMemo(
      () =>
        curveCreatePool.data
          ?.map(({ blockNumber, long1 }) => ({
            blockNumber,
            poolId: Number(long1 || 0),
          }))
          .reduce(
            (prev: Record<number, number>, { poolId, blockNumber }) => ({
              ...prev,
              [poolId]: blockNumber,
            }),
            {},
          ),
      [curveCreatePool.data],
    );

    const curveTokenExchange = useFetch(
      {
        query: chainId
          ? `${apiBaseUrl}/events?chainId=${chainId}&section=curveAmm&method=TokenExchange`
          : undefined,
        method: "get",
        parsePayload,
        predicate: eventsPredicate,
      },
      false,
    );

    return {
      curvePoolCreated,
      curveTokenExchange: curveTokenExchange.data,
    };
  },
);

export const useCurveApi = Context.useContext;
export const CurveApiProvider = Context.Provider;
