import { ApiPromise, ApiRx } from "@polkadot/api";
import { WsProvider } from "@polkadot/rpc-provider";
import { map } from "rxjs/operators";
import getDefs, { chainType } from "./defs";
import derives from "./derives";
import mapQuery from "./mappers/query";
import mapTx from "./mappers/tx";
import getRpc from "./rpc";
const getOptions = (nodes, options, specName) => {
    const provider = new WsProvider(nodes);
    return {
        ...getDefs(specName),
        derives,
        provider,
        ...options,
    };
};
export const createApi = async (...params) => {
    const opt = getOptions(...params);
    const api = await ApiPromise.create(opt);
    const rpc = { ...api.rpc, ...getRpc(api) };
    const query = mapQuery(api);
    const tx = mapTx(api);
    return {
        /** @deprecated */
        getAccounts: async () => (await api.query.system.account.keys()).map((key) => key.args[0].toHuman()),
        /** @deprecated */
        getBlock: api.rpc.chain.getBlock,
        /** @deprecated */
        getBlockHash: api.rpc.chain.getBlockHash,
        /** @deprecated */
        getError: (...args) => api.registry.findMetaError(...args),
        /** @deprecated */
        getNonce: api.rpc.system.accountNextIndex,
        /** @deprecated */
        subscribeNewBlockHeads: api.rpc.chain.subscribeNewHeads,
        /** @deprecated */
        batch: api.tx.utility?.batch,
        /** @deprecated */
        sudo: api.tx.sudo?.sudo,
        /** @deprecated */
        _api: api,
        _type: chainType,
        derive: api.derive,
        events: api.events,
        multi: api.queryMulti,
        onError: (cb) => opt.provider.on("error", cb),
        query,
        registry: api.registry,
        rpc,
        setSigner: (signer) => api.setSigner(signer),
        tx,
    };
};
export const createApiRx = (...params) => {
    const opt = getOptions(...params);
    return ApiRx.create(opt).pipe(map((api) => {
        const rpc = { ...api.rpc, ...getRpc(api) };
        const query = mapQuery(api);
        const tx = mapTx(api);
        return {
            /** @deprecated */
            getAccounts: api.query.system.account
                .keys()
                .pipe(map((keys) => keys.map((key) => key.args[0].toHuman()))),
            /** @deprecated */
            getBlock: api.rpc.chain.getBlock,
            /** @deprecated */
            getBlockHash: api.rpc.chain.getBlockHash,
            /** @deprecated */
            getError: (...args) => api.registry.findMetaError(...args),
            /** @deprecated */
            getNonce: api.rpc.system.accountNextIndex,
            /** @deprecated */
            subscribeNewBlockHeads: api.rpc.chain.subscribeNewHeads,
            /** @deprecated */
            batch: api.tx.utility.batch,
            /** @deprecated */
            _api: api,
            _type: chainType,
            derive: api.derive,
            events: api.events,
            multi: api.queryMulti,
            onError: (cb) => opt.provider.on("error", cb),
            query,
            registry: api.registry,
            rpc,
            setSigner: (signer) => api.setSigner(signer),
            tx,
        };
    }));
};
