import React, { useMemo, useState } from "react";
import { t } from "ttag";
import BigNumber from "bignumber.js";
import Sidebar from "@equilab/ui/lib/components/sidebar";
import { EthTx } from "@equilab/ui/lib/components/cross-chain/eth/types";
import { DepositERC20 } from "@equilab/ui/lib/components/cross-chain/eth";
import Select from "@equilab/ui/lib/components/select";
import { useNotifications } from "@equilab/ui/lib/contexts/notifications";
import { useApi } from "@equilab/ui/lib/contexts/polkadot/api";
import { useObsState } from "@equilab/ui/lib/hooks/rxjs";
import { getCrosschainCfg } from "../../config";
import { useConvert } from "../../contexts/features/convert";
import { useAssets } from "../../contexts/observables/assets";
import { assetsFilter } from "../../util";
import { getIconsList } from "./constants";
import { Wrapper, CCBox } from "./styled";

const BIG_INF = new BigNumber(Infinity);

const CurveSidebar = ({
  wrapperRef,
}: {
  wrapperRef: React.MutableRefObject<null>;
}) => {
  const assets$ = useAssets();
  const assetInfo = useObsState(assets$);
  const convert = useConvert();

  const {
    accounts,
    network,
    networks,
    tab,
    balance,
    token,
    hasAssets,
    withdrawExtrinsic,
    transferExtrinsic,
    createTransferExtrinsic,
    createWithdrawExtrinsic,
    setToken,
    selectAccount,
    selectNetwork,
    openSettings,
    getAccount,
    setTab,
    close,
  } = Sidebar.useSidebar(convert);

  const networkTypeName = React.useMemo(
    () =>
      network?.node.includes("testnet")
        ? "testnet"
        : network?.node.includes("devnet")
        ? "devnet"
        : "mainnet",
    [network],
  );

  const crosschainCfg = React.useMemo(
    () => getCrosschainCfg(networkTypeName),
    [networkTypeName],
  );

  const { sendMessage, sendError } = useNotifications();
  const { api: pApi } = useApi();

  const [isZeroBalanceHidden, setIsZeroBalanceHidden] = useState(false);
  const [chainIndex, setChainIndex] = React.useState(0);

  const account = useMemo(() => getAccount(), [getAccount]);

  const { walletBalance } = React.useMemo(() => {
    const assets = Object.keys(assetInfo ?? {});
    if (!balance || !account?.address || !balance?.[account.address]) {
      return {
        walletBalance: Object.keys(assets ?? {}).reduce<
          Record<string, BigNumber>
        >((prev, token) => ({ ...prev, [token]: BIG_INF }), {}),

        suggest: undefined,
      };
    }

    return {
      walletBalance: Object.fromEntries(
        Object.entries(balance[account.address])
          .map((o) => o)
          .filter(([token, _]) => assetsFilter(token))
          .filter(
            (o) =>
              !(isZeroBalanceHidden && new BigNumber(0).eq(o[1] as BigNumber)),
          ),
      ),
    };
  }, [balance, account, isZeroBalanceHidden, assetInfo]);

  const onDepositSuccess = React.useCallback(
    (
        setInputDisabled: (val: boolean) => void,
        setDepositTx: (tx: EthTx) => void,
      ) =>
      async (target: "weth" | "approve" | "deposit", tx: EthTx) => {
        if (target === "approve") {
          setInputDisabled(true);
        }

        if (tx.transactionHash) {
          const shrinkedHash =
            tx.transactionHash.slice(0, 6) +
            "..." +
            tx.transactionHash.slice(-4);

          sendMessage({
            content: `ETH Tx: ${shrinkedHash} successful`,
            href: `https://etherscan.io/tx/${tx.transactionHash}`,
          });
        }

        if (target === "deposit") {
          setDepositTx(tx);
        }
      },
    [close, sendMessage],
  );

  const onDepositError = React.useCallback(
    async (target: "weth" | "approve" | "deposit", e: Error) => {
      if (target === "approve") {
        // TODO refactor disabling input and enable input here again
      }

      sendError({
        content: e.message,
      });
    },
    [sendError],
  );

  const chainDeposit = React.useMemo(() => {
    const cfg = crosschainCfg[token];
    const crossChain = cfg[chainIndex];

    return (props: {
      amount?: string;
      setAmount: (val: string) => void;
      setInputDisabled: (val: boolean) => void;
      setDepositTx: (tx: EthTx) => void;
      setStep: (val: string) => void;
      setBridgeBalance?: (val: string) => void;
    }) => (
      <CCBox p="12px 0">
        {cfg.length > 1 ? (
          <Select
            onSelect={(val) => {
              const num = Number(val);

              if (Number.isFinite(num)) {
                setChainIndex(num);
              }
            }}
            preselectedKey={"0"}
            title={t`Source chain`}
            width="100%"
          >
            {cfg.map(({ name }, i) => (
              <Select.Option key={i.toString()}>
                {name.length > 16 ? name.slice(0, 16) + ".." : name}
              </Select.Option>
            ))}
          </Select>
        ) : undefined}

        <DepositERC20
          convert={convert}
          address={account?.address!}
          amount={props.amount}
          dest={crossChain.hostChainId}
          resourceId={crossChain.resourceId}
          spender={crossChain.chainSpec?.ethSpenderAddress!}
          bridgeAddress={crossChain.chainSpec?.ethBridgeAddress!}
          tokenAddress={crossChain.chainSpec?.ethTokenAddress!}
          wrap={crossChain.chainSpec?.ethWrap}
          onSuccess={onDepositSuccess(
            props.setInputDisabled,
            props.setDepositTx,
          )}
          onError={onDepositError}
          onCopy={props.setAmount}
          setStep={props.setStep}
          setBridgeBalance={props.setBridgeBalance ?? (() => {})}
          token={token}
          chainName={crossChain.name}
          chainId={crossChain.chainSpec?.ethNetworkId}
        />
      </CCBox>
    );
  }, [
    network,
    token,
    account,
    onDepositError,
    onDepositSuccess,
    chainIndex,
    crosschainCfg,
  ]);

  if (!account) {
    return null;
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Sidebar.Header account={account} onClick={openSettings} />
      {tab === "settings" && (
        <Sidebar.Settings
          account={account}
          accounts={accounts ?? []}
          network={network ? { ...network, dev: true } : network}
          networks={networks}
          onClose={close}
          onAccountChange={selectAccount}
          onNetworkChange={selectNetwork}
        />
      )}
      {tab === "wallet" && (
        <Sidebar.Actions
          onSwitch={setTab}
          network={network ? { ...network, dev: true } : network}
        />
      )}
      {tab === "wallet" && (
        <Sidebar.Wallet
          onSwitch={setTab}
          convert={convert}
          account={account}
          balance={walletBalance}
          toggleZeroBalanceHide={() =>
            setIsZeroBalanceHidden(!isZeroBalanceHidden)
          }
          isZeroBalanceHidden={isZeroBalanceHidden}
          isTokenClickable={false}
          icons={getIconsList(Object.keys(walletBalance)).reduce(
            (prev, [name, Component]) => ({
              ...prev,
              [name as string]: <Component />,
            }),
            {},
          )}
        />
      )}
      {tab === "deposit" && (
        <Sidebar.Deposit
          token={token}
          setToken={setToken}
          onClose={close}
          network={network}
          hasAssets={hasAssets}
          convert={convert}
        >
          {chainDeposit}
        </Sidebar.Deposit>
      )}
      {tab === "withdraw" && (
        <Sidebar.Withdraw
          config={crosschainCfg}
          token={token}
          setToken={setToken}
          balance={balance?.[account.address]}
          onClose={close}
          account={account}
          onError={(e) => {
            // @ts-ignore
            if (e?.error && pApi) {
              const err = pApi!.getError(e as any);
              sendError({
                content: `${err.name}: ${err.docs.join("")}`,
              });
            }
          }}
          onSuccess={() => {
            sendMessage({ content: t`Withdraw successfull` });
            // TODO: add refresh history
            // setTimeout(() => refreshHistory(), 12000);
          }}
          extrinsic={withdrawExtrinsic}
          onChange={createWithdrawExtrinsic}
        />
      )}

      {tab === "transfer" && (
        <Sidebar.Transfer
          token={token}
          setToken={setToken}
          balance={balance?.[account.address]}
          onClose={close}
          account={account}
          onError={(e) => {
            // @ts-ignore
            if (e?.error && pApi) {
              const err = pApi!.getError(e as any);

              sendError({
                content: `${err.name}: ${err.docs.join("")}`,
              });
            }
          }}
          onSuccess={() => {
            sendMessage({ content: t`Transfer successfull` });
            // TODO: add refresh history
            // setTimeout(() => refreshHistory(), 12000);
          }}
          extrinsic={transferExtrinsic}
          onChange={createTransferExtrinsic}
        />
      )}
    </Wrapper>
  );
};

export default CurveSidebar;
