import type { Locale } from "../../locales";
import { LinkButton } from "@equilab/ui/lib/components/button";
import Icons from "@equilab/ui/lib/components/icons";
import Link from "@equilab/ui/lib/components/dex-navigation/components/link";
import Space from "@equilab/ui/lib/components/space";
import ScreenView from "@equilab/ui/lib/components/screen-view";
import Text from "@equilab/ui/lib/components/text";
import { useGetLang, useLink, useLang } from "@equilab/ui/lib/hooks";
import cn from "classnames";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import Routes from "./routes";

import {
  Main,
  Navigation,
  Page,
  Subtitle,
  MobilePlaceholderWrapper,
} from "./styled";

import Wallet from "./wallet";
import Sidebar from "../sidebar";
import { NetworkToogle } from "../common/controls/network-toogle";
import Loading from "../common/loading";
import { useAccounts } from "@equilab/ui/lib/contexts/polkadot/connect";

const App: React.FC = () => {
  const lang = useGetLang<Locale>();

  const langs: Record<Locale, string> = React.useMemo(
    () => ({ en: t`EN`, zh: t`CH`, ko: t`KR` }),
    [lang],
  );

  const switchLang = useLang();
  const to = useLink();
  const { account } = useAccounts();
  const match = useRouteMatch<{ lang?: string; 0?: string }>("/:lang/(.*)");
  const ref = React.useRef(null); // TODO dispose
  const [show, setShow] = React.useState(false);

  const onLinkClick = React.useCallback(
    (dest: string) => (e: React.MouseEvent) => {
      e.preventDefault();
      to(dest);
    },
    [to],
  );

  useEffect(() => {
    console.info(`version: ${process.env.REACT_APP_VERSION}`);
  }, []);

  return (
    <Page id="page-container">
      <ScreenView.Large>
        <Navigation
          hideBurger
          //@ts-ignore
          useAccounts={useAccounts}
          active={show}
          toggleWallet={() => {
            setShow((val) => !val);
          }}
          subtitle={
            <Subtitle>
              Epsilon.&nbsp;
              <a href="https://equilibrium.io" target="_blank">
                by&nbsp;Equilibrium
              </a>
            </Subtitle>
          }
        >
          <li style={{ opacity: 0.4 }}>
            <NetworkToogle />
          </li>

          <Link
            active={!match?.params?.[0]}
            href={`/${lang}`}
            onClick={onLinkClick("")}
            margin="0 0 0 38px"
          >
            <Text.F1>{t`Buy/Sell`}</Text.F1>
          </Link>

          <Link
            active={!!match?.params?.[0]?.includes("manage")}
            href={`/${lang}/manage`}
            onClick={onLinkClick("manage")}
            margin="0 0 0 24px"
          >
            <Text.F1>{t`Deposit/Withdraw`}</Text.F1>
          </Link>

          {!!account && (
            <Link
              active={!!match?.params?.[0]?.includes("dashboard")}
              href={`/${lang}/dashboard`}
              onClick={onLinkClick("dashboard")}
              margin="0 0 0 24px"
            >
              <Text.F1>{t`Dashboard`}</Text.F1>
            </Link>
          )}
        </Navigation>

        <Main>
          <Routes>
            <Loading />
          </Routes>
        </Main>

        <Wallet show={show} setShow={setShow}>
          <React.Suspense fallback={<div />}>
            <Sidebar wrapperRef={ref} />
          </React.Suspense>
          {/* TODO lazy load */}
        </Wallet>
        <Space height={80} />
        {/* <DefaultFooter lang={lang} /> */}
      </ScreenView.Large>
      <ScreenView.NotLarge>
        <MobilePlaceholderWrapper>
          <nav>
            <Icons.Curve />

            <Text.F2 className="mobile-title">Epsilon</Text.F2>

            <Text.F3
              as="a"
              className="mobile-by"
              target="_blank"
              href="https://equilibrium.io"
            >
              by Equilibrium
            </Text.F3>

            <div className="langs">
              {(Object.keys(langs) as Locale[]).map((l) => (
                <div
                  className={cn("lang-item", { active: l === lang })}
                  key={l}
                  onClick={() => {
                    if (l !== lang) {
                      switchLang(l);
                    }
                  }}
                >
                  {langs[l]}
                </div>
              ))}
            </div>
          </nav>
          <Text.H2>{t`Please, use desktop version to login`}</Text.H2>

          <LinkButton
            target="_blank"
            className="home"
            href="https://genshiro.equilibrium.io"
            size="md"
          >{t`Go to genshiro`}</LinkButton>

          {
            // @ts-ignore
            window.navigator.canShare?.({
              title: "Epsilon",
              url: "https://epsilon.io",
            }) ? (
              <LinkButton
                className="share"
                texted
                href="/"
                onClick={async (e) => {
                  e.preventDefault();

                  window.navigator?.share?.({
                    title: "Epsilon",
                    url: "https://epsilon.io",
                  });
                }}
              >{t`Share link`}</LinkButton>
            ) : undefined
          }
        </MobilePlaceholderWrapper>
      </ScreenView.NotLarge>
    </Page>
  );
};
export default App;
