export const STORAGE_KEYS = {
  //util_crypto.xxhashAsHex(capitalize(<NAME>), 128);
  PALLETS: {
    SYSTEM: "26aa394eea5630e07c48ae0c9558cef7",
  },
  METHODS: {
    ACCOUNT: "b99d880ec681799c0cf30e8886371da9",
    EVENTS: "80d41e5e16056765bc8461851072c9d7",
  },
};

export const FALLBACK_MAX_HASH_COUNT = 250;
export const FALLBACK_PERIOD = 6 * 1000;
export const MAX_FINALITY_LAG = 5;
export const MORTAL_PERIOD = 5 * 60 * 1000;

export const CHAIN = {
  KUSAMA: {
    EXTRINSIC_VERSION: 4,
    SYSTEM_BLOCKHASHCOUNT: 2400,
    BABE_EXPECTEDBLOCKTIME: 6000,
    TIMESTAMP_MINIMUMPERIOD: 3000,
  },
};

export const FAKE_SIGNATURE =
  "0x0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101";

// TODO get rid of magic consts
export const MAGIC_VAR = "84"; // 8 - signedExtrinsic; 4 -> extrinsic version
