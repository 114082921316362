import DefaultNavigation from "@equilab/ui/lib/components/dex-navigation";
import {
  Main as UiMain,
  Page as DefaultPage,
} from "@equilab/ui/lib/components/grid";
import Text from "@equilab/ui/lib/components/text";
import styled from "styled-components";

export const Navigation = styled(DefaultNavigation)`
  li:first-of-type {
    margin-left: 0;
  }

  .ui-header__item {
    position: relative;
    p {
      font-family: SpartanBold;
    }

    transition: 0.2s;
    opacity: 0.4;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  .ui-header__dropdown {
    padding-left: 32px;
    padding-right: 32px;

    .ui-header__item {
      opacity: 1;
      transition: unset;

      &:hover::after,
      &.active::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background: ${(props) => props.theme.colors?.border ?? "#DABAEC"};
        bottom: 0;
      }
    }
    .ui-header__link:hover {
      background: ${(props) => props.theme.colors?.secondary};
    }
    :hover .ui-header__list {
      border-top: 2px solid
        ${(props) => props.theme.colors?.border ?? "#DABAEC"};
    }
  }

  .ui-dex-nav-connect-button {
    // background: #ffffff;
    :hover {
      // background: ${(props) => props.theme.colors?.primary};
    }
  }
`;

export const Subtitle = styled(Text.F2)`
  font-size: 16px;
  margin-left: -36px;
  font-family: SpartanMedium;

  a {
    text-decoration: none;
    font-size: 12px;
    color: ${(props) => props.theme.colors?.primary};
  }
`;

export const Page = styled(DefaultPage)`
  position: relative;

  #background {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

export const Main = styled(UiMain)`
  padding-top: 100px;
  z-index: 2;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  z-index: 97;
  .back-button {
    box-shadow: unset;
    background: transparent;

    :hover {
      background: rgba(204, 70, 226, 0.15);
    }
  }
`;

export const BG = styled.div<{ show: boolean }>(
  ({ show }) => `
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    width: 100vw;

    background: #403a6480;
    transition: opacity 0.2s;
    opacity: ${show ? "1" : "0"};
`,
);

export const Inner = styled.div<{ isOpenWallet: boolean }>(
  ({ isOpenWallet }) => `
    position: fixed;
    top: 74px;
    right: calc(50% - 183px - 430px);

    max-width: 366px;
    width: 100%;

    transition: 0.4s;
    transform: ${isOpenWallet ? "unset" : "translateY(-820px)"};
`,
);

export const MobilePlaceholderWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 48px);
  margin: 0 24px;
  height: 100vh;
  padding-top: 32px;

  background: linear-gradient(
    180deg,
    #ffffff 19.36%,
    rgba(255, 255, 255, 0.73) 41.49%,
    rgba(255, 255, 255, 0.26) 96.63%
  );

  box-shadow: 0px 4px 4px rgba(209, 189, 251, 0.8);
  justify-content: center;
  align-items: center;

  > p {
    margin-bottom: 24px;
    padding: 0 48px;
    line-height: 32px;

    text-align: center;
  }

  > .home,
  > .share {
    font-size: 14px;
    margin-top: 8px;
  }

  nav {
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    width: 100vw;
    height: 48px;
    background: white;
    align-items: center;
    top: 0;
    left: 0;
    filter: drop-shadow(0px 4px 10px rgba(209, 189, 251, 0.7));

    > svg {
      height: 24px;
      margin-left: 24px;
    }

    .mobile-title {
      margin-left: 2px;
    }

    .mobile-by {
      margin-left: 8px;
      text-decoration: none;
    }

    .langs {
      margin-left: auto;
      display: flex;
      align-items: center;
      padding: 0 24px;

      > .lang-item {
        cursor: pointer;
        margin: 0 4px;
        color: ${(props) => props.theme.colors?.primary};
        opacity: 0.2;

        &.active {
          cursor: default;
          font-weight: bold;
          opacity: 1;
        }
      }
    }
  }
`;
