import { BN } from "@polkadot/util/bn/bn";

export const currencyFromU64 = (u64: number | BN): Lowercase<string> => {
  const bytes: number[] = [];
  let num = typeof u64 === "number" ? new BN(u64) : u64;

  do {
    bytes.unshift(num.modn(256));
    num = num.divn(256);
  } while (num.gtn(0));

  return Buffer.from(bytes).toString("utf8").toLowerCase();
};

export const u64FromCurrency = (currency: string) => {
  const buf = Buffer.from(currency.toLowerCase());
  const size = buf.length;

  return buf.reduce(
    (val, digit, i) => val + Math.pow(256, size - 1 - i) * digit,
    0,
  );
};

export const DEFAULT_DECIMALS = 9;
