import { ApiConfig } from "./types";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useApi } from "@equilab/ui/lib/contexts/polkadot/api";
import { useAsyncMemo, useFetch } from "@equilab/ui/lib/hooks";
import { useMemo } from "react";
import { parsePayload } from "./util";

const predicate = (raw: unknown): raw is { chainId: number } =>
  !!raw && Object.prototype.hasOwnProperty.call(raw, "chainId");

const Context = createSimpleContext(
  "api-chain-id",
  undefined,
  ({ apiBaseUrl }: ApiConfig) => {
    const { api } = useApi();

    const genesis = useAsyncMemo(async () => {
      const hash = await api?.getBlockHash(0);
      return hash && hash.toHex();
    }, [api]);

    const query = useMemo(() => {
      if (genesis.data) {
        return `${apiBaseUrl}/chains/byhash?hash=${genesis?.data}`;
      }
    }, [apiBaseUrl, genesis?.data]);

    const chainIdReq = useFetch({
      query,
      method: "get",
      parsePayload,
      predicate,
    });

    return chainIdReq.data?.chainId
      ? Number(chainIdReq.data.chainId)
      : undefined;
  },
);

export const ChainIdProvider = Context.Provider;
export const useChainId = Context.useContext;
