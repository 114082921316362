import { currencyFromU64 } from "@equilab/api/genshiro/util";
import { createSimpleContext } from "@equilab/ui/lib/contexts/simple";
import { useApi } from "@equilab/ui/lib/contexts/polkadot/api";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { defaultAssests } from "./defaults";

type AssetType = "native" | "physical" | "synthetic" | "lp";

type CachedAssets = Record<
  string,
  {
    debtWeight: string;
    type: AssetType;
  }
>;

type ParsedAssets = Record<
  string,
  {
    debtWeight: BigNumber;
    type: AssetType;
  }
>;

const PREC_128 = new BigNumber(10).pow(-18);
const cacheLocalStorageKey = "assets-rx-cache";

const Context = createSimpleContext("assets-rx", of({}), () => {
  const { apiRx } = useApi<"Gens">();

  return useMemo(() => {
    const lsCache = localStorage.getItem("assets-rx-cache");

    return (
      apiRx?.query.eqAssets?.assets().pipe(
        map((res) => {
          // @ts-expect-error
          if (!res.isSome) {
            return {};
          }

          // @ts-expect-error
          const vec = res.unwrap().toArray();

          // @ts-expect-error
          return vec.reduce<CachedAssets>((prev, data) => {
            const token = currencyFromU64(data.id[0].toNumber());

            return {
              ...prev,
              [token]: {
                debtWeight: data.debt_weight.toString(10),
                type: data.asset_type.isNative
                  ? "native"
                  : data.asset_type.isPhysical
                  ? "physical"
                  : data.asset_type.isSynthetic
                  ? "synthetic"
                  : "lp",
              },
            };
          }, {});
        }),
        tap((cached) => {
          localStorage.setItem(cacheLocalStorageKey, JSON.stringify(cached));
        }),
      ) ??
      of(
        lsCache
          ? (JSON.parse(lsCache) as CachedAssets)
          : (defaultAssests as CachedAssets),
      )
    ).pipe(
      map((cached) =>
        Object.keys(cached).reduce<ParsedAssets>((prev, token) => {
          const entry = cached[token];

          return {
            ...prev,
            [token]: {
              debtWeight: PREC_128.times(entry.debtWeight),
              type: entry.type,
            },
          };
        }, {}),
      ),
    );
  }, [apiRx]);
});

export const AssetsProvider = Context.Provider;
export const useAssets = Context.useContext;
