import { genshiro } from "@equilab/definitions";
import { names } from "./assets/names";
import { createCustomAccount } from "./custom-account";
import { bestPrice } from "./dex/best-price";
import { orders } from "./dex/orders";
const derives = {
    // @ts-ignore
    assets: { names },
    // @ts-ignore
    dex: { bestPrice, orders },
};
for (const curr of genshiro.instances.balances) {
    // @ts-ignore
    derives[curr] = { customAccount: createCustomAccount(curr) };
}
export default derives;
