import Text from "@equilab/ui/lib/components/text";
import React from "react";
import styled from "styled-components";
import { t } from "ttag";

const Wrapper = styled.div<{ activeNetwork: "kusama" | "polkadot" }>(
  ({ activeNetwork }) => `
  position: relative;

  width: 234px;
  height: 44px;

  margin-left: 28px;
  margin-right: 90px;
  padding: 5px;

  display: flex;
  align-items: center;

  background: linear-gradient(
    270.18deg,
    #ffddee -11.96%,
    #e4dcff 26.28%,
    #dae8fc 80.25%
  );
  border-radius: 11px;

  > button {
    width: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: unset;
    border: unset;

    cursor: pointer;

    z-index: 2;
  }

  ::after {
    content: "";

    background: #ffffff;
    border-radius: 9px;

    width: 50%;
    height: 34px;

    position: absolute;
    left: ${activeNetwork == "kusama" ? "5px" : "calc(50% - 5px)"};
    top: 5px;
    bottom: 5px;

    z-index: 1;
    transition: 0.2s;
  }
`,
);

export const NetworkToogle = () => {
  const [activeNetwork, setActiveNetwork] = React.useState<
    "kusama" | "polkadot"
  >("kusama");

  return (
    <Wrapper activeNetwork={activeNetwork}>
      <button disabled onClick={() => setActiveNetwork("kusama")}>
        <Text.T4 fontFamily="SpartanBold">Kusama</Text.T4>
      </button>
      <button disabled onClick={() => setActiveNetwork("polkadot")}>
        <Text.T4 fontFamily="SpartanBold">Polkadot</Text.T4>
      </button>
    </Wrapper>
  );
};
